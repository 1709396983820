import * as actionsType from './actionsType'

const initialState = {
    isLoading: false,
    expenditureData: {
        expenditureList: [],
        total: 0
    },
    expenditureDetails: {},
    profitLossIncomeData: {
        profitLossIncomeList: [],
        total: 0,
        total_income: 0
    },
    profitLossExpensesData: {
        profitLossExpensesList: [],
        total: 0,
        total_expense: 0
    },
}
export default function reportsReducer(state = { ...initialState }, action) {
    const { type, payload } = action;

    switch (type) {
        case actionsType.GET_ALL_EXPENDITURE_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_ALL_EXPENDITURE_SUCCESS:
            return { ...state, isLoading: false, expenditureData: { ...state.expenditureData, expenditureList: payload.data, total: payload.total } }
        case actionsType.GET_ALL_EXPENDITURE_FAILED:
            return { ...state, isLoading: false }

        //ADD
        case actionsType.GET_ADD_EXPENDITURE_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_ADD_EXPENDITURE_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_ADD_EXPENDITURE_FAILED:
            return { ...state, isLoading: false }

        //DELETE
        case actionsType.GET_DELETE_EXPENDITURE_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_DELETE_EXPENDITURE_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_DELETE_EXPENDITURE_FAILED:
            return { ...state, isLoading: false }

        //GET BY ID 
        case actionsType.GET_EXPENDITURE_BY_ID_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_EXPENDITURE_BY_ID_SUCCESS:
            return { ...state, isLoading: false, expenditureDetails: payload }
        case actionsType.GET_EXPENDITURE_BY_ID_FAILED:
            return { ...state, isLoading: false }

        //  UPDATE
        case actionsType.GET_UPDATE_EXPENDITURE_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_UPDATE_EXPENDITURE_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_UPDATE_EXPENDITURE_FAILED:
            return { ...state, isLoading: false }

        //PROFIT LOSS
        //Income
        case actionsType.GET_PROFIT_LOSS_INCOME_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_PROFIT_LOSS_INCOME_SUCCESS:
            return {
                ...state,
                isLoading: false,
                profitLossIncomeData: {
                    ...state.profitLossIncomeData,
                    profitLossIncomeList: payload && payload.income_data && payload.income_data.data || [],
                    total: payload && payload.income_data && payload.income_data.total || 0,
                    total_income: payload && payload.total_income || 0
                }
            }
        case actionsType.GET_PROFIT_LOSS_INCOME_FAILED:
            return { ...state, isLoading: false }

        //Expenses
        case actionsType.GET_PROFIT_LOSS_EXPENSES_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_PROFIT_LOSS_EXPENSES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                profitLossExpensesData: {
                    ...state.profitLossExpensesData,
                    profitLossExpensesList: payload && payload.expense_data && payload.expense_data.data || [],
                    total: payload && payload.expense_data && payload.expense_data.total || 0,
                    total_expense: payload && payload.total_expense || 0
                }
            }
        case actionsType.GET_PROFIT_LOSS_EXPENSES_FAILED:
            return { ...state, isLoading: false }
        default:
            return state
    }

}