import * as actionsType from './actionsType'

const initialState = {
    isLoading: false,
    venues: [],
    templates: []
}

export default function venuesReducer(state = { ...initialState }, action) {
    switch (action.type) {
        //Get All Venues API
        case actionsType.GET_ALL_VENUE_REQUEST: {
            return { ...state, isLoading: true }
        }
        case actionsType.GET_ALL_VENUE_SUCCESS: {
            return { ...state, venues: action.payload, isLoading: false }
        }
        case actionsType.GET_ALL_VENUE_FAILED: {
            return { ...state, isLoading: false }
        }

        //Delete Venue
        case actionsType.DELETE_VENUE_REQUEST: {
            return { ...state, isLoading: true }
        }
        case actionsType.DELETE_VENUE_SUCCESS: {
            return { ...state, isLoading: false }
        }
        case actionsType.DELETE_VENUE_FAILED: {
            return { ...state, isLoading: false }
        }
        //Get All Templates API
        case actionsType.GET_ALL_TEMPLATES_REQUEST: {
            return { ...state, isLoading: true }
        }
        case actionsType.GET_ALL_TEMPLATES_SUCCESS: {
            return { ...state, templates: action.payload, isLoading: false }
        }
        case actionsType.GET_ALL_TEMPLATES_FAILED: {
            return { ...state, isLoading: false }
        }

        //Template status
        case actionsType.TEMPLATE_STATUS_REQUEST: {
            return { ...state, isLoading: true }
        }
        case actionsType.TEMPLATE_STATUS_SUCCESS: {
            return { ...state, isLoading: false }
        }
        case actionsType.TEMPLATE_STATUS_FAILED: {
            return { ...state, isLoading: false }
        }
        //Venue edit by id
        case actionsType.GET_VENUE_BY_ID_REQUEST: {
            return { ...state, isLoading: true }
        }
        case actionsType.GET_VENUE_BY_ID_SUCCESS: {
            return { ...state, isLoading: false }
        }
        case actionsType.GET_VENUE_BY_ID_FAILED: {
            return { ...state, isLoading: false }
        }

        //Venue update
        case actionsType.VENUE_CREATE_UPDATE_REQUEST: {
            return { ...state, isLoading: true }
        }
        case actionsType.VENUE_CREATE_UPDATE_SUCCESS: {
            return { ...state, isLoading: false }
        }
        case actionsType.VENUE_CREATE_UPDATE_FAILED: {
            return { ...state, isLoading: false }
        }

        default: {
            return state;
        }
    }
}


