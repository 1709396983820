import * as actionType from './actionsType'

const initialState = {
    isLoading: false,
    cms: {},
};

export default function cmsReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case actionType.GET_CMS_REQUEST:
            return { ...state, cms: {}, isLoading: true };
        case actionType.GET_CMS_SUCCESS:
            return { ...state, cms: action.payload.data, isLoading: false };
        case actionType.GET_CMS_FAILED:
            return { ...state, cms: {}, isLoading: false };
            //add
        case actionType.ADD_CMS_REQUEST:
            return { ...state,  isLoading: true };
        case actionType.ADD_CMS_SUCCESS:
            return { ...state, isLoading: false };
        case actionType.ADD_CMS_FAILED:
            return { ...state,  isLoading: false };
            //update
        case actionType.UPDATE_CMS_REQUEST:
            return { ...state, isLoading: true };
        case actionType.UPDATE_CMS_SUCCESS:
            return { ...state, isLoading: false };
        case actionType.UPDATE_CMS_FAILED:
            return { ...state, isLoading: false };
        default:
            return state;
    }
}
