
import * as actionsType from './actionsType';
import { loginAPI, changePasswordAPI, logoutAPI, resetPasswordRequestAPI, verifyOtpAPI, resetPasswordAPI, forgotResendOtpAPI } from './api';

export function signIn(data) {
    return dispatch => {
        dispatch({
            type: actionsType.ADMIN_LOGIN_INITIATE
        })
        return new Promise((resolve, reject) => {
            loginAPI(data)
                .then((res) => {
                    dispatch({
                        type: actionsType.ADMIN_LOGIN_SUCCESS,
                        payload: res
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'success',
                            message: res.message
                        }
                    })
                    return resolve(res);
                })
                .catch(err => {
                    dispatch({
                        type: actionsType.ADMIN_LOGIN_FAILURE
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'error',
                            message: err && err.response && err.response.data && err.response.data.message || err.message
                        }
                    })
                    return reject(err)
                })
        })
    }
}




//CHANGE PASSWORD
export function changePassword(data) {
    return dispatch => {
        dispatch({
            type: actionsType.CHANGE_PASSWORD_INITIATE
        })
        return new Promise((resolve, reject) => {
            changePasswordAPI(data)
                .then((res) => {
                    dispatch({
                        type: actionsType.CHANGE_PASSWORD_SUCCESS,
                        payload: res
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'success',
                            message: res.message
                        }
                    })
                    return resolve(res);
                })
                .catch(err => {
                    dispatch({
                        type: actionsType.CHANGE_PASSWORD_FAILURE
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'error',
                            message: err && err.response && err.response.data && err.response.data.message || err.message
                        }
                    })
                    return reject(err)
                })
        })
    }
}



//logout 
export function signOut(data) {
    return dispatch => {
        dispatch({
            type: actionsType.ADMIN_SIGNOUT_REQUEST
        })
        return new Promise((resolve, reject) => {
            logoutAPI(data)
                .then((res) => {
                    dispatch({
                        type: actionsType.ADMIN_SIGNOUT_SUCCESS,
                        payload: res
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'success',
                            message: res.message
                        }
                    })
                    return resolve(res);
                })
                .catch(err => {
                    dispatch({
                        type: actionsType.ADMIN_SIGNOUT_FAILED
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'error',
                            message: err && err.response && err.response.data && err.response.data.message || err.message
                        }
                    })
                    return reject(err)
                })
        })
    }
}

//Reset password request
export function resetPasswordRequest(data) {
    return dispatch => {
        dispatch({
            type: actionsType.RESET_PASSWORD_REQUEST_INITIATE
        })
        return new Promise((resolve, reject) => {
            resetPasswordRequestAPI(data)
                .then((res) => {
                    dispatch({
                        type: actionsType.RESET_PASSWORD_REQUEST_SUCCESS,
                        // payload: res
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'success',
                            message: res.message
                        }
                    })
                    return resolve(res);
                })
                .catch(err => {
                    dispatch({
                        type: actionsType.RESET_PASSWORD_REQUEST_FAILED
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'error',
                            message: err && err.response && err.response.data && err.response.data.messages || err.message
                        }
                    })
                    return reject(err)
                })
        })
    }
}



//Verify otp
export function verifyOtp(data) {
    return dispatch => {
        dispatch({
            type: actionsType.VERIFY_OTP_INITIATE
        })
        return new Promise((resolve, reject) => {
            verifyOtpAPI(data)
                .then((res) => {
                    dispatch({
                        type: actionsType.VERIFY_OTP_SUCCESS,
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'success',
                            message: res.message
                        }
                    })
                    return resolve(res);
                })
                .catch(err => {
                    dispatch({
                        type: actionsType.VERIFY_OTP_FAILED
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'error',
                            message: err && err.response && err.response.data && err.response.data.message || err.message
                        }
                    })
                    return reject(err)
                })
        })
    }
}



//reset passsword
export function resetPassword(data) {
    return dispatch => {
        dispatch({
            type: actionsType.RESET_PASSWORD_INITIATE
        })
        return new Promise((resolve, reject) => {
            resetPasswordAPI(data)
                .then((res) => {
                    dispatch({
                        type: actionsType.RESET_PASSWORD_SUCCESS,
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'success',
                            message: res.message
                        }
                    })
                    return resolve(res);
                })
                .catch(err => {
                    dispatch({
                        type: actionsType.RESET_PASSWORD_FAILED
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'error',
                            message: err && err.response && err.response.data && err.response.data.message || err.message
                        }
                    })
                    return reject(err)
                })
        })
    }
}


//forgotResendOtpAPI

export function forgotResendOtp(data) {
    return dispatch => {
        dispatch({
            type: actionsType.FORGOT_RESEND_OTP_INITIATE
        })
        return new Promise((resolve, reject) => {
            forgotResendOtpAPI(data)
                .then((res) => {
                    dispatch({
                        type: actionsType.FORGOT_RESEND_OTP_SUCCESS,
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'success',
                            message: res.message
                        }
                    })
                    return resolve(res);
                })
                .catch(err => {
                    dispatch({
                        type: actionsType.FORGOT_RESEND_OTP_FAILED
                    })
                    dispatch({
                        type: actionsType.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'error',
                            message: err && err.response && err.response.data && err.response.data.message || err.message
                        }
                    })
                    return reject(err)
                })
        })
    }
}
