
export const GET_ALL_VENUE_REQUEST = "GET_ALL_VENUE_REQUEST";
export const GET_ALL_VENUE_SUCCESS = "GET_ALL_VENUE_SUCCESS";
export const GET_ALL_VENUE_FAILED = "GET_ALL_VENUE_FAILED";

export const GET_ALL_TEMPLATES_REQUEST = "GET_ALL_TEMPLATES_REQUEST";
export const GET_ALL_TEMPLATES_SUCCESS = "GET_ALL_TEMPLATES_SUCCESS";
export const GET_ALL_TEMPLATES_FAILED = "GET_ALL_TEMPLATES_FAILED";

export const GET_VENUE_BY_ID_REQUEST = "GET_VENUE_BY_ID_REQUEST";
export const GET_VENUE_BY_ID_SUCCESS = "GET_VENUE_BY_ID_SUCCESS";
export const GET_VENUE_BY_ID_FAILED = "GET_VENUE_BY_ID_FAILED";

export const VENUE_CREATE_UPDATE_REQUEST = "VENUE_CREATE_UPDATE_REQUEST";
export const VENUE_CREATE_UPDATE_SUCCESS = "VENUE_CREATE_UPDATE_SUCCESS";
export const VENUE_CREATE_UPDATE_FAILED = "VENUE_CREATE_UPDATE_FAILED"

export const DELETE_VENUE_REQUEST = "DELETE_VENUE_REQUEST";
export const DELETE_VENUE_SUCCESS = "DELETE_VENUE_SUCCESS";
export const DELETE_VENUE_FAILED = "DELETE_VENUE_FAILED";

export const TEMPLATE_STATUS_REQUEST = "TEMPLATE_STATUS_REQUEST";
export const TEMPLATE_STATUS_SUCCESS = "TEMPLATE_STATUS_SUCCESS";
export const TEMPLATE_STATUS_FAILED = "TEMPLATE_STATUS_FAILED";

export const OPEN_SNACKBAR_STATE = 'OPEN_SNACKBAR_STATE'
export const CLOSE_SNACKBAR_STATE = 'CLOSE_SNACKBAR_STATE'

