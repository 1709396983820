import { combineReducers } from 'redux'
import dashboard from '../containers/Dashboard/reducer'
import common from '../containers/common/reducer'
import auth from '../containers/Auth/reducer'
import sellers from '../containers/Sellers/reducer'
import events from '../containers/Events/reducer'
import venues from '../containers/Venues/reducer'
import transactions from '../containers/Transactions/reducer'
import tickets from '../containers/Tickets/reducer'
import vouchers from '../containers/GiftVouchers/reducer'
import reports from '../containers/Reports/reducer'
import cms from '../containers/Cms/reducer'
export default combineReducers({
    dashboard,
    common,
    auth,
    sellers,
    events,
    venues,
    transactions,
    tickets,
    vouchers,
    reports,
    cms

});
