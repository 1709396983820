import { apiDelete, apiGet, apiPost, apiPut } from "../../utils";
import { pickBy } from 'lodash'

export function getAllEventAPI(data) {
    const updateData = pickBy(data)
    return apiGet('admin/event/get-all', updateData);
}

export function getAllCategoriesAPI(data) {
    const updateData = pickBy(data)
    return apiGet('admin/event/request-event', updateData);
}

export function getDeleteEventByIdAPI(id) {
    return apiDelete(`admin/event/${id}`);
}

export function getEventByIdAPI(id) {
    return apiGet(`admin/event`, { id });
}

export function uploadImagesAPI(data = {}) {
    let formData = new FormData();
    formData.append('image', data.image);

    return apiPost('admin/event/uplaod', formData);
}

export function getUpdateEventAPI(data) {
    return apiPut('admin/event', data);
}

export function changeStatusAPI(data) {
    return apiPut('admin/event/update-status', data)
}
export function changeReOpenStatusAPI(data) {
    return apiPut('admin/event/reopen', data)
}

export function eventFeesAPI(data) {
    return apiPost('admin/event/fee', data)
}

export function getEventFeesAPI(data) {
    return apiGet('admin/event/fee', data)
}

export function sendBuyerEmailAPI(data) {
    return apiPost('admin/event/send-email', data);
}


export function getEventExtraFieldByIdAPI(id) {
    return apiDelete(`admin/event/extra-field/${id}`);
}
