export const OPEN_SNACKBAR_STATE = 'OPEN_SNACKBAR_STATE'
export const CLOSE_SNACKBAR_STATE = 'CLOSE_SNACKBAR_STATE'

export const GET_ALL_VOUCHERS_REQUEST = "GET_ALL_VOUCHERS_REQUEST";
export const GET_ALL_VOUCHERS_SUCCESS = "GET_ALL_VOUCHERS_SUCCESS";
export const GET_ALL_VOUCHERS_FAILED = "GET_ALL_VOUCHERS_FAILED";

export const GET_ADD_VOUCHER_REQUEST = "GET_ADD_VOUCHER_REQUEST";
export const GET_ADD_VOUCHER_SUCCESS = "GET_ADD_VOUCHER_SUCCESS";
export const GET_ADD_VOUCHER_FAILED = "GET_ADD_VOUCHER_FAILED";

export const GET_VOUCHER_BY_ID_REQUEST = "GET_VOUCHER_BY_ID_REQUEST";
export const GET_VOUCHER_BY_ID_SUCCESS = "GET_VOUCHER_BY_ID_SUCCESS";
export const GET_VOUCHER_BY_ID_FAILED = "GET_VOUCHER_BY_ID_FAILED";

export const GET_UPDATE_VOUCHER_REQUEST = "GET_UPDATE_VOUCHER_REQUEST";
export const GET_UPDATE_VOUCHER_SUCCESS = "GET_UPDATE_VOUCHER_SUCCESS";
export const GET_UPDATE_VOUCHER_FAILED = "GET_UPDATE_VOUCHER_FAILED";

export const GET_DELETE_VOUCHER_BY_ID_REQUEST = "GET_DELETE_VOUCHER_BY_ID_REQUEST";
export const GET_DELETE_VOUCHER_BY_ID_SUCCESS = "GET_DELETE_VOUCHER_BY_ID_SUCCESS";
export const GET_DELETE_VOUCHER_BY_ID_FAILED = "GET_DELETE_VOUCHER_BY_ID_FAILED";

export const GET_VOUCHER_TRANSACTIONS_REQUEST = "GET_VOUCHER_TRANSACTIONS_REQUEST";
export const GET_VOUCHER_TRANSACTIONS_SUCCESS = "GET_VOUCHER_TRANSACTIONS_SUCCESS";
export const GET_VOUCHER_TRANSACTIONS_FAILED = "GET_VOUCHER_TRANSACTIONS_FAILED";

