import * as actionsType from './actionsType'

const initialState = {
    isLoading: false,
    sellersData: {
        sellers: [],
        total: 0
    },
    sellerDetails: {},
    eventDetails: {},
    event_booking: [],
    paymentsDetails: {}
}
export default function sellerReducer(state = { ...initialState }, action) {
    const { type, payload } = action;

    switch (type) {
        case actionsType.GET_ALL_SELLERS_REQUEST:
            return { ...state, isLoading: true, sellers: [] }
        case actionsType.GET_ALL_SELLERS_SUCCESS:
            return { ...state, isLoading: false, sellersData: { ...state.sellersData, sellers: payload.data, total: payload.total } }
        case actionsType.GET_ALL_SELLERS_FAILED:
            return { ...state, isLoading: false, sellers: [] }

        //GET SELLER BY  ID
        case actionsType.GET_SELLERS_BY_ID_REQUEST:
            return { ...state, isLoading: true, sellerDetails: {} }
        case actionsType.GET_SELLERS_BY_ID_SUCCESS:
            return { ...state, isLoading: false, sellerDetails: payload }
        case actionsType.GET_SELLERS_BY_ID_FAILED:
            return { ...state, isLoading: false, sellerDetails: {} }

        //SELLER STATUS CHANGE
        case actionsType.SELLER_ACTIVE_STATUS_REQUEST:
            return { ...state, isLoading: true, }
        case actionsType.SELLER_ACTIVE_STATUS_SUCCESS:
            return { ...state, isLoading: false, }
        case actionsType.SELLER_ACTIVE_STATUS_FAILED:
            return { ...state, isLoading: false, }

        //GET SELLER EVENT BY  ID
        case actionsType.GET_SELLERS_EVENT_BY_ID_REQUEST:
            return { ...state, isLoading: true, eventDetails: {} }
        case actionsType.GET_SELLERS_EVENT_BY_ID_SUCCESS:
            let eventBooking = payload && payload.event_booking || {}
            return { ...state, isLoading: false, eventDetails: payload.event, event_booking: eventBooking }
        case actionsType.GET_SELLERS_EVENT_BY_ID_FAILED:
            return { ...state, isLoading: false, eventDetails: {} }

        //ADD SELLER 
        case actionsType.GET_ADD_SELLER_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_ADD_SELLER_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_ADD_SELLER_FAILED:
            return { ...state, isLoading: false }

        //UPDATE SELLER 
        case actionsType.GET_UPDATE_SELLER_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_UPDATE_SELLER_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_UPDATE_SELLER_FAILED:
            return { ...state, isLoading: false }
        //GET PAYMENTS
        case actionsType.GET_PAYMENTS_BY_ID_REQUEST:
            return { ...state, isLoading: true, paymentsDetails: {} }
        case actionsType.GET_PAYMENTS_BY_ID_SUCCESS:
            return { ...state, isLoading: false, paymentsDetails: payload }
        case actionsType.GET_PAYMENTS_BY_ID_FAILED:
            return { ...state, isLoading: false, paymentsDetails: {} }

        //POST PAYMENTS
        case actionsType.GET_PAYMENTS_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_PAYMENTS_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_PAYMENTS_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }

}