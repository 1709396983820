import { apiGet, apiPost, apiPut } from '../../utils'

export function loginAPI(data) {
    const formdata = new FormData();

    Object.keys(data).map(item => {
        formdata.append(item, data[item])
    })
    return apiPost('admin/login', formdata);
}

export function changePasswordAPI(data) {
    return apiPut('admin/change-password', data);
}

export function logoutAPI(data) {
    return apiPost('admin/logout', data);
}

export function resetPasswordRequestAPI(data) {
    return apiPost('reset-password-request', data)
}

export function verifyOtpAPI(data) {
    return apiPost('verify-otp', data)
}

export function resetPasswordAPI(data) {
    return apiPost('reset-password', data)
}

export function forgotResendOtpAPI(data) {
    return apiGet(`forgot-resend-otp`, data)
}