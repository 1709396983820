import * as actionsType from './actionsType'

const initialState = {
    isLoading: false,
    vouchersData: [],
    voucherDetails: {},
    vouchersTransactionsData: {}
}
export default function vouchersReducer(state = { ...initialState }, action) {
    const { type, payload } = action;

    switch (type) {
        //get all voucher
        case actionsType.GET_ALL_VOUCHERS_REQUEST:
            return { ...state, isLoading: true, vouchersData: [] }
        case actionsType.GET_ALL_VOUCHERS_SUCCESS:
            return { ...state, isLoading: false, vouchersData: payload }
        case actionsType.GET_ALL_VOUCHERS_FAILED:
            return { ...state, isLoading: false, vouchersData: [] }

        // add voucer
        case actionsType.GET_ADD_VOUCHER_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_ADD_VOUCHER_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_ADD_VOUCHER_FAILED:
            return { ...state, isLoading: false }

        // get voucer by id
        case actionsType.GET_VOUCHER_BY_ID_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_VOUCHER_BY_ID_SUCCESS:
            return { ...state, isLoading: false, voucherDetails: payload }
        case actionsType.GET_VOUCHER_BY_ID_FAILED:
            return { ...state, isLoading: false }

        // update voucer
        case actionsType.GET_UPDATE_VOUCHER_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_UPDATE_VOUCHER_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_UPDATE_VOUCHER_FAILED:
            return { ...state, isLoading: false }

        // get delete voucer by id
        case actionsType.GET_DELETE_VOUCHER_BY_ID_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_DELETE_VOUCHER_BY_ID_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_DELETE_VOUCHER_BY_ID_FAILED:
            return { ...state, isLoading: false }
        //get voucher transaction list 
        case actionsType.GET_VOUCHER_TRANSACTIONS_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_VOUCHER_TRANSACTIONS_SUCCESS:
            return { ...state, isLoading: false, vouchersTransactionsData: payload }
        case actionsType.GET_VOUCHER_TRANSACTIONS_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }

}