import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Lottie from 'react-lottie';

import { ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import theme from './theme'

import { getData } from './utils'
import { AccessTokenId, defaultOptions, lottie } from './constants'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'

import * as commonActions from './containers/common/action'

// Modal component
import ChangePasswordModal from './components/Auth/ChangePasswordModal'
import CustomDialog from './components/common/CustomDialog'
import ProfitLossModal from './components/Auth/ProfitLossModal'
import ExpenditureModal from './components/Auth/ExpenditureModal'
import DeleteModal from './containers/common/DeleteModal'
import ExtraFieldModal from './components/common/ExtraFieldModal'
import SendEmailModal from './components/Events/SendEmailModal';

//Components
const SignIn = lazy(() => import('./containers/Auth/SignIn'));
const ResetPassword = lazy(() => import('./containers/Auth/ResetPassword'));

const Dashboard = lazy(() => import('./containers/Dashboard'));

const Sellers = lazy(() => import('./containers/Sellers'));
const SellerDetails = lazy(() => import('./containers/Sellers/SellerDetails'));
const AddSeller = lazy(() => import('./containers/Sellers/AddSeller'));
const Payment = lazy(() => import('./containers/Sellers/Payment'));

const Events = lazy(() => import('./containers/Events'));
const EditEvent = lazy(() => import('./containers/Events/EditEvent'));
const EventFees = lazy(() => import('./containers/Events/EventFees'));
const EventDetail = lazy(() => import('./containers/Sellers/EventDetail'));


const Transactions = lazy(() => import('./containers/Transactions'));
const Statement = lazy(() => import('./containers/Transactions/Statement'));

const Tickets = lazy(() => import('./containers/Tickets'));
const TicketsRefund = lazy(() => import('./containers/Tickets/TicketsRefund'));
const TicketEdit = lazy(() => import('./containers/Tickets/TicketEdit'));
const TicketSeatChange = lazy(() => import('./containers/Tickets/TicketSeatChange'));

const GiftVouchures = lazy(() => import('./containers/GiftVouchers'));
const AddVoucher = lazy(() => import('./containers/GiftVouchers/AddVoucher'));


const Expenditure = lazy(() => import('./containers/Reports/Expenditure'));
const ProfitLoss = lazy(() => import('./containers/Reports/ProfitLoss'));


const Venues = lazy(() => import('./containers/Venues'));
const CreateVenue = lazy(() => import('./containers/Venues/Create_venue'));
const Venue_details = lazy(() => import('./containers/Venues/Venue_details'));
const Create_venue_scratch = lazy(() => import('./containers/Venues/Create_venue_scratch'));
const Cms = lazy(() => import('./containers/Cms'));

const PageNotFound = lazy(() => import('./containers/PageNotFound'));


function isAuth() {
    let result = getData(AccessTokenId);
    return result;
}

//Public routes
function PublicRoute(props) {
    const { component: Component, restricted = false, ...rest } = props

    const render = props => {
        if (isAuth()) {
            return <Redirect to="/" />
        }
        return <Component {...props} />
    }
    return <Route {...rest} render={render} />
}

//Private routes
function PrivateRoute(props) {

    const { component: Component, ...rest } = props;

    //Auth check
    const render = props => {
        if (!isAuth()) {
            return <Redirect to="/login" />
        }
        return <Component {...props} />
    }
    return <Route {...rest} render={render} />
}


export default function Routes() {
    return (
        <Suspense fallback={<div className="loading-wrapper-custom">
            <div className="loader">
                {/* <CircularProgress color="inherit" /> */}
                <Lottie options={defaultOptions}
                    height={150}
                    width={150}
                    isStopped={false}
                    isPaused={false}
                />
            </div>
        </div>}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <Switch>
                        <PrivateRoute path="/" exact component={Dashboard} />
                        <PublicRoute path="/login" exact component={SignIn} />
                        <PublicRoute path="/reset_password" exact component={ResetPassword} />
                        <PrivateRoute path="/dashboard" exact component={Dashboard} />

                        <PrivateRoute path="/sellers" exact component={Sellers} />
                        <PrivateRoute path="/sellers/event/:id" exact component={EventDetail} />
                        <PrivateRoute path="/sellers/add_seller" exact component={AddSeller} />
                        <PrivateRoute path="/sellers/:id/payment" exact component={Payment} />
                        <PrivateRoute path="/sellers/:id" exact component={SellerDetails} />

                        <PrivateRoute path="/events" exact component={Events} />
                        <PrivateRoute path="/events/event_fees" exact component={EventFees} />
                        <PrivateRoute path="/events/edit/:id" exact component={EditEvent} />
                        <PrivateRoute path="/event/:id" exact component={EventDetail} />

                        <PrivateRoute path="/transactions" exact component={Transactions} />
                        <PrivateRoute path="/transactions/statement" exact component={Statement} />

                        <PrivateRoute path="/tickets" exact component={Tickets} />
                        <PrivateRoute path="/tickets/refund/:id" exact component={TicketsRefund} />
                        <PrivateRoute path="/tickets/edit/:id" exact component={TicketEdit} />
                        <PrivateRoute path="/tickets/edit/:ids/zone-seat/:zoneId" exact component={TicketSeatChange} />

                        <PrivateRoute path="/gift_vouchers" exact component={GiftVouchures} />
                        <PrivateRoute path="/gift_vouchers/add_voucher" exact component={AddVoucher} />

                        <PrivateRoute path="/reports/expenditure" exact component={Expenditure} />
                        <PrivateRoute path="/reports/profit_loss" exact component={ProfitLoss} />

                        <PrivateRoute path="/venues" exact component={Venues} />
                        <PrivateRoute path="/venues/create_venue" exact component={CreateVenue} />
                        <PrivateRoute path="/venues/create_venue_scratch" exact component={Create_venue_scratch} />
                        <PrivateRoute path="/venues/:id" exact component={Venue_details} />
                        <PrivateRoute path="/venues/create_venue_scratch/:ids" exact component={Create_venue_scratch} />
                        <PrivateRoute path="/cms" exact component={Cms} />
                        <PublicRoute component={PageNotFound} />
                    </Switch>
                    <SnackBar />
                    <CustomDialogModal />
                </Router>
            </ThemeProvider>
        </Suspense>
    )
}

function SnackBar() {
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(commonActions.closeSnackbarsState())
    }
    const { open = false, messageType = '', message = '' } = useSelector(state => state.common.snackbar)
    return <Snackbar className="top-bar-msg" open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity={messageType}>
            {message}
        </Alert>
    </Snackbar>
}


function CustomDialogModal() {
    const modal = useSelector(state => state.common.modal);
    switch (modal) {
        case "RESET_PASSWORD_MODAL": {
            return <RenderModalWithComponent component={ChangePasswordModal} maxWidth="lg" className="modal-md" />
        }
        case "PROFIT_LOSS_MODAL": {
            return <RenderModalWithComponent component={ProfitLossModal} maxWidth="lg" className="modal-sm  modal-expenditure-main" />
        }
        case "EXPENDITURE_MODAL": {
            return <RenderModalWithComponent component={ExpenditureModal} maxWidth="lg" className="modal-sm modal-expenditure-main" />
        }
        case "ADD_EXTRA_FIELD_MODAL": {
            return <RenderModalWithComponent component={ExtraFieldModal} maxWidth="lg" className="modal-sm" />
        }
        case "OPEN_COMMON_MODAL": {
            return <RenderModalWithComponent component={DeleteModal} maxWidth="lg" className="modal-sm" />
        }
        case "SEND_BUYER_EMAIL": {
            return <RenderModalWithComponent component={SendEmailModal} maxWidth="lg" className="modal-sm" />
        }
        default:
            return null
    }
}

const RenderModalWithComponent = ({ component: Component, maxWidth = "sm", className = "", isCloseIconButton = false, ...rest }) => {
    return (
        <>
            <CustomDialog fullWidth maxWidth={maxWidth} customClassName={className} isCloseIconButton={isCloseIconButton}>
                <Component {...rest} />
            </CustomDialog>
        </>
    )
}
