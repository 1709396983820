import React from "react";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

export default function DeleteModal(props) {
    const { data } = useSelector(state => state.common);
    const { isLoading: isEventLoading } = useSelector(state => state.events)
    const { isLoading: isVenueLoading } = useSelector(state => state.venues)
    const { title = '', customFunction = () => { }, buttonTitle = '' } = data;

    const confirmAction = () => {
        customFunction();
    }

    return <div className="cus-delete-modal">
        <Grid container>
            <Grid item xs={12}>
                <div className="db-sub-heading text-center">
                    <Typography variant="h3" className="w-100">{title}</Typography>
                </div>
                <div className="delete-modal-btn">
                    <Button
                        className="cus-btn blue-btn"
                        fullWidth
                        onClick={() => { confirmAction() }}
                        disabled={isEventLoading || isVenueLoading}
                    >
                        {(isEventLoading || isVenueLoading) ? <CircularProgress color="inherit" size={20} />
                            : buttonTitle}
                    </Button>
                </div>
            </Grid>
        </Grid>
    </div>
}