export const OPEN_SNACKBAR_STATE = 'OPEN_SNACKBAR_STATE'
export const CLOSE_SNACKBAR_STATE = 'CLOSE_SNACKBAR_STATE'

export const GET_ALL_EXPENDITURE_REQUEST = "GET_ALL_EXPENDITURE_REQUEST";
export const GET_ALL_EXPENDITURE_SUCCESS = "GET_ALL_EXPENDITURE_SUCCESS";
export const GET_ALL_EXPENDITURE_FAILED = "GET_ALL_EXPENDITURE_FAILED";

export const GET_ADD_EXPENDITURE_REQUEST = "GET_ADD_EXPENDITURE_REQUEST";
export const GET_ADD_EXPENDITURE_SUCCESS = "GET_ADD_EXPENDITURE_SUCCESS";
export const GET_ADD_EXPENDITURE_FAILED = "GET_ADD_EXPENDITURE_FAILED";

export const GET_DELETE_EXPENDITURE_REQUEST = "GET_DELETE_EXPENDITURE_REQUEST";
export const GET_DELETE_EXPENDITURE_SUCCESS = "GET_DELETE_EXPENDITURE_SUCCESS";
export const GET_DELETE_EXPENDITURE_FAILED = "GET_DELETE_EXPENDITURE_FAILED";

export const GET_UPDATE_EXPENDITURE_REQUEST = "GET_UPDATE_EXPENDITURE_REQUEST";
export const GET_UPDATE_EXPENDITURE_SUCCESS = "GET_UPDATE_EXPENDITURE_SUCCESS";
export const GET_UPDATE_EXPENDITURE_FAILED = "GET_UPDATE_EXPENDITURE_FAILED";

export const GET_EXPENDITURE_BY_ID_REQUEST = "GET_EXPENDITURE_BY_ID_REQUEST";
export const GET_EXPENDITURE_BY_ID_SUCCESS = "GET_EXPENDITURE_BY_ID_SUCCESS";
export const GET_EXPENDITURE_BY_ID_FAILED = "GET_EXPENDITURE_BY_ID_FAILED";

export const GET_PROFIT_LOSS_INCOME_REQUEST = "GET_PROFIT_LOSS_INCOME_REQUEST";
export const GET_PROFIT_LOSS_INCOME_SUCCESS = "GET_PROFIT_LOSS_INCOME_SUCCESS";
export const GET_PROFIT_LOSS_INCOME_FAILED = "GET_PROFIT_LOSS_INCOME_FAILED";

export const GET_PROFIT_LOSS_EXPENSES_REQUEST = "GET_PROFIT_LOSS_EXPENSES_REQUEST";
export const GET_PROFIT_LOSS_EXPENSES_SUCCESS = "GET_PROFIT_LOSS_EXPENSES_SUCCESS";
export const GET_PROFIT_LOSS_EXPENSES_FAILED = "GET_PROFIT_LOSS_ EXPENSES_FAILED";