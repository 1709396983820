import { getAllExpenditureAPI, getAddExpenditureAPI, getDeleteExpenditureAPI, getExpenditureByIdAPI, getUpdateExpenditureAPI, getProfitLossIncomeAPI, getProfitLossExpensesAPI } from './api'
import * as actionsType from './actionsType'

export function getAllExpenditure(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_ALL_EXPENDITURE_REQUEST
        })

        return new Promise((resolve, reject) => {
            getAllExpenditureAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_ALL_EXPENDITURE_SUCCESS,
                    payload: res && res.data,
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_ALL_EXPENDITURE_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//Add expenditure 
export function getAddExpenditure(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_ADD_EXPENDITURE_REQUEST
        })

        return new Promise((resolve, reject) => {
            getAddExpenditureAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_ADD_EXPENDITURE_SUCCESS,
                    payload: res && res.data
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'success',
                        message: res && res.message
                    }
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_ADD_EXPENDITURE_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//delete expenditure 
export function getDeleteExpenditure(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_DELETE_EXPENDITURE_REQUEST
        })

        return new Promise((resolve, reject) => {
            getDeleteExpenditureAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_DELETE_EXPENDITURE_SUCCESS,
                    payload: res && res.data
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'success',
                        message: res && res.message
                    }
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_DELETE_EXPENDITURE_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//Get by id 
export function getExpenditureById(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_EXPENDITURE_BY_ID_REQUEST
        })

        return new Promise((resolve, reject) => {
            getExpenditureByIdAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_EXPENDITURE_BY_ID_SUCCESS,
                    payload: res && res.data,
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_EXPENDITURE_BY_ID_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//update
export function getUpdateExpenditure(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_UPDATE_EXPENDITURE_REQUEST
        })

        return new Promise((resolve, reject) => {
            getUpdateExpenditureAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_UPDATE_EXPENDITURE_SUCCESS,
                    payload: res && res.data
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'success',
                        message: res && res.message
                    }
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_UPDATE_EXPENDITURE_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//Get profit loss income 
export function getProfitLossIncome(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_PROFIT_LOSS_INCOME_REQUEST
        })

        return new Promise((resolve, reject) => {
            getProfitLossIncomeAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_PROFIT_LOSS_INCOME_SUCCESS,
                    payload: res && res.data || {},
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_PROFIT_LOSS_INCOME_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//Get profit loss expenses
export function getProfitLossExpenses(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_PROFIT_LOSS_EXPENSES_REQUEST
        })

        return new Promise((resolve, reject) => {
            getProfitLossExpensesAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_PROFIT_LOSS_EXPENSES_SUCCESS,
                    payload: res && res.data,
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_PROFIT_LOSS_EXPENSES_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}
