import React, { useState, useEffect } from "react";
import { Typography, Container, Grid, Button, TextField } from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux'
import CustomInputField from "../common/CustomInputField";
import * as  commonActions from '../../containers/common/action'
import Autocomplete from "@material-ui/lab/Autocomplete";

import * as eventActions from "../../containers/Events/actions";
import { sendEmailsValidatin } from "../../utilities/validations/promotor/event";

const initialState = {
    email: [],
    subject: "",
    body: ""
}
export default function SendEmailModal() {
    const dispatch = useDispatch();
    const { data: commonData, isLoading } = useSelector(state => state.common);
    const t = useSelector(state => state.common)
    let { transactions = [] } = commonData;
    const [errors, setErrors] = useState({})

    //removing empty email
    transactions = transactions && transactions.filter(transaction => {
        const email = transaction && transaction.buyer && transaction.buyer.email || ""
        if (email) {
            return email
        }
    })
    transactions = transactions && transactions.map(transaction => {
        const email = transaction && transaction.buyer && transaction.buyer.email || ""
        if (email) {
            return email
        }
    })
    // removing duplicate 
    transactions = transactions && [... new Set(transactions)].map(email => ({ title: email, value: email })) || []


    const [data, setData] = useState({
        ...initialState
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: ""
        })

    }
    const hanldeChangeEmail = (e, emailsData) => {
        const emails = emailsData && emailsData.map(val => val.value)
        setData({
            ...data,
            email: emails
        })
        setErrors({
            ...errors,
            email: ""
        })
    }
    const handleSubmit = () => {
        const { errors, isValid } = sendEmailsValidatin(data)
        if (!isValid) {
            setErrors(errors)
            return
        }
        dispatch(eventActions.sendBuyerEmail(data)).then(res => {

            dispatch(commonActions.closeModal())
        }).catch(err => {

        })

    }
    return (
        <>
            <div className="confirm-booking-modal apply-promocode-modal">
                <Container>
                    <Grid container>
                        <Grid item xs={12} className="your-ticket-sec">

                            <div className="cus-sec-heading text-center">
                                <img src="/images/flat.svg" className="pb-5" />
                                <Typography variant="h2">Share Information With Your Customers</Typography>
                            </div>
                            <Autocomplete
                                multiple
                                className="mt-10 modal-multi-select"
                                limitTags={2}
                                id="multiple-buyers-tags"
                                options={transactions || []}
                                getOptionLabel={(option) => option.title}
                                onChange={(e, value) => hanldeChangeEmail(e, value)}
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Email" placeholder="Enter email" />
                                )}
                            />
                            {errors?.email && <p className="error mt-10">{errors?.email}</p>}

                            <CustomInputField
                                label="Subject"
                                fullWidth
                                name="subject"
                                value={data?.subject || ""}
                                onChange={(event) => { handleChange(event) }}
                                margin="normal"
                                error={errors?.subject || ""}
                            />
                            <CustomInputField
                                label="Body"
                                fullWidth
                                multiline
                                rows={3}
                                name="body"
                                value={data?.body || ""}
                                onChange={(event) => { handleChange(event) }}
                                margin="normal"
                                error={errors?.body || ""}
                            />
                            <div className="print-btn">
                                <Button className="cus-btn blue-btn" onClick={() => handleSubmit()}>Send </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div >
        </>
    )
}
