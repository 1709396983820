import {
    CLOSE_MODAL_DIALOG,
    OPEN_MODAL_DIALOG,
    OPEN_SNACKBAR_STATE,
    CLOSE_SNACKBAR_STATE,
    GET_ALL_NOTIFICATION_REQUEST,
    GET_ALL_NOTIFICATION_SUCCESS,
    GET_ALL_NOTIFICATION_FAILED,
    GET_READ_NOTIFICATION_REQUEST,
    GET_READ_NOTIFICATION_SUCCESS,
    GET_READ_NOTIFICATION_FAILED,
    GET_SEEN_NOTIFICATION_REQUEST,
    GET_SEEN_NOTIFICATION_SUCCESS,
    GET_SEEN_NOTIFICATION_FAILED,
    GET_IP_ADDRESS_REQUEST,
    GET_IP_ADDRESS_SUCCESS,
    GET_IP_ADDRESS_FAILED,
    GET_VENUES_BY_STATE_REQUEST,
    GET_VENUES_BY_STATE_SUCCESS,
    GET_VENUES_BY_STATE_FAILED
} from './actionTypes';

const initialState = {
    isOpenDialog: false,
    data: {},
    modal: null,
    isLoading: false,
    snackbar: {
        open: false,
        messageType: '',
        message: ''
    },
    notificationsData: {
        notifications: [],
        total: 0,
        total_unseen: 0,
        last_page: 0,
        current_page: 0
    },
    ipAddress: '',
    venuesData: []
}

export default function commonReducer(state = { ...initialState }, action) {
    switch (action.type) {

        case OPEN_MODAL_DIALOG: {
            return { ...state, isOpenDialog: true, modal: action.modal, data: action.data }
        }
        case CLOSE_MODAL_DIALOG: {
            return { ...state, isOpenDialog: false, modal: action.modal, data: action.data }
        }
        //   SnackBar Reducer
        case OPEN_SNACKBAR_STATE: {
            const { messageType = 'success', message = '' } = action.payload || {}
            return { ...state, snackbar: { ...state.snackbar, open: true, messageType, message } }
        }

        case CLOSE_SNACKBAR_STATE: {
            return { ...state, snackbar: { ...state.snackbar, open: false } }
        }
        //get all notification
        case GET_ALL_NOTIFICATION_REQUEST:
            return { ...state, isLoading: true }
        case GET_ALL_NOTIFICATION_SUCCESS: {
            const notificationPayload = action && action.payload || {}
            const isloadMore = action.loadMore

            const notifications = notificationPayload && notificationPayload.notifications && notificationPayload.notifications.data || []
            const total = notificationPayload && notificationPayload.notifications && notificationPayload.notifications.total || 0
            const total_unseen = notificationPayload && notificationPayload.total_unseen || 0
            const last_page = notificationPayload && notificationPayload.notifications && notificationPayload.notifications.last_page || 0
            const current_page = notificationPayload && notificationPayload.notifications && notificationPayload.notifications.current_page || 0

            if (isloadMore) {
                return {
                    ...state, isLoading: false, notificationsData: { ...state.notificationsData, notifications: [...state.notificationsData.notifications, ...notifications], total, total_unseen, last_page, current_page }
                }
            } else {
                return {
                    ...state, isLoading: false, notificationsData: { ...state.notificationsData, notifications: notifications, total, total_unseen, last_page, current_page }
                }
            }

        }
        case GET_ALL_NOTIFICATION_FAILED:
            return { ...state, isLoading: false }
        //get read notification
        case GET_READ_NOTIFICATION_REQUEST:
            return { ...state, isLoading: true }
        case GET_READ_NOTIFICATION_SUCCESS:
            return { ...state, isLoading: false }
        case GET_READ_NOTIFICATION_FAILED:
            return { ...state, isLoading: false }
        //get seen notification
        case GET_SEEN_NOTIFICATION_REQUEST:
            return { ...state, isLoading: true }
        case GET_SEEN_NOTIFICATION_SUCCESS:
            return { ...state, isLoading: false }
        case GET_SEEN_NOTIFICATION_FAILED:
            return { ...state, isLoading: false }
        //IP address
        case GET_IP_ADDRESS_REQUEST:
            return { ...state, isLoading: true }
        case GET_IP_ADDRESS_SUCCESS:
            return { ...state, isLoading: false, ipAddress: action.payload }
        case GET_IP_ADDRESS_FAILED:
            return { ...state, isLoading: false }

        case GET_VENUES_BY_STATE_REQUEST:
            return { ...state, isLoading: true }
        case GET_VENUES_BY_STATE_SUCCESS:
            return { ...state, isLoading: false, venuesData: action.payload && action.payload || [] }
        case GET_VENUES_BY_STATE_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }
}


