
import * as actionsType from './actionsType'

const initialState = {
  isLoading: false,

  seatsDetails: {},
  zoneDetails: {},
  ticketsData: {
    ticketsDetails: [],
    total: 0,
    currentPage: 0
  },
  editTicketData: {},
  zoneSeatData: {},
  ticketDeatils: {}
}
export default function ticketsReducer(state = { ...initialState }, action) {
  const { type, payload } = action;

  switch (type) {
    case actionsType.GET_TICKETS_REQUEST:
      return { ...state, isLoading: true }
    case actionsType.GET_TICKETS_SUCCESS:
      const isLoadMore = action && action.loadMore || false
      const newData = payload && payload.data || []
      const fitlerData = isLoadMore ? [...state.ticketsData.ticketsDetails, ...newData] : newData;
      const ticketsData = {
        ticketsDetails: fitlerData,
        total: payload && payload.total || 0,
        lastPage: payload && payload.lastPage || 0,
        currentPage: payload && payload.currentPage || 0
      }
      return { ...state, isLoading: false, ticketsData }
    case actionsType.GET_TICKETS_FAILED:
      return { ...state, isLoading: false }

    case actionsType.GET_TICKETS_EDIT_REQUEST:
      return { ...state, isLoading: true }
    case actionsType.GET_TICKETS_EDIT_SUCCESS:
      return { ...state, isLoading: false, editTicketData: payload }
    case actionsType.GET_TICKETS_EDIT_FAILED:
      return { ...state, isLoading: false }

    case actionsType.GET_SEAT_DETATILS_REQUEST:
      return { ...state, isLoading: true }
    case actionsType.GET_SEAT_DETATILS_SUCCESS:
      return { ...state, isLoading: false, seatsDetails: payload }
    case actionsType.GET_SEAT_DETATILS_FAILED:
      return { ...state, isLoading: false }

    case actionsType.GET_PERFORMANCE_ZONES_REQUEST:
      return { ...state, isLoading: true }
    case actionsType.GET_PERFORMANCE_ZONES_SUCCESS: {
      let data = createPerformaceZoneData(action.payload);
      let { event = {} } = action.payload;
      let { pricing_model = "sell" } = event;
      return { ...state, isLoading: false, zoneDetails: data, pricingModel: pricing_model }
    }
    case actionsType.GET_PERFORMANCE_ZONES_FAILED:
      return { ...state, isLoading: false }

    //GET_ZONE_SEAT_SUCCESS
    case actionsType.GET_ZONE_SEAT_REQUEST:
      return { ...state, isLoading: true }
    case actionsType.GET_ZONE_SEAT_SUCCESS:
      return { ...state, isLoading: false, zoneSeatData: payload }
    case actionsType.GET_ZONE_SEAT_FAILED:
      return { ...state, isLoading: false }

    //REFUND
    case actionsType.GET_REFUND_REQUEST:
      return { ...state, isLoading: true }
    case actionsType.GET_REFUND_SUCCESS:
      return { ...state, isLoading: false }
    case actionsType.GET_REFUND_FAILED:
      return { ...state, isLoading: false }

    //SEND TO BUYER
    case actionsType.GET_SEND_TO_BUYER_REQUEST:
      return { ...state, isLoading: true }
    case actionsType.GET_SEND_TO_BUYER_SUCCESS:
      return { ...state, isLoading: false }
    case actionsType.GET_SEND_TO_BUYER_FAILED:
      return { ...state, isLoading: false }

    //GET TICKET BY ID
    case actionsType.GET_TICKET_BY_ID_REQUEST:
      return { ...state, isLoading: true }
    case actionsType.GET_TICKET_BY_ID_SUCCESS:
      return { ...state, isLoading: false, ticketDeatils: payload || {} }
    case actionsType.GET_TICKET_BY_ID_FAILED:
      return { ...state, isLoading: false }

    //SEAT CHANGE
    case actionsType.GET_SEAT_CHANGE_REQUEST:
      return { ...state, isLoading: true }
    case actionsType.GET_SEAT_CHANGE_SUCCESS:
      return { ...state, isLoading: false }
    case actionsType.GET_SEAT_CHANGE_FAILED:
      return { ...state, isLoading: false }


    //BOOKING STATUS CHANGE
    case actionsType.GET_BOOKING_STATUS_CHANGE_REQUEST:
      return { ...state, isLoading: true }
    case actionsType.GET_BOOKING_STATUS_CHANGE_SUCCESS:
      return { ...state, isLoading: false }
    case actionsType.GET_PERFORMANCE_ZONES_FAILED:
      return { ...state, isLoading: false }


    default:
      return state
  }

}

function createPerformaceZoneData(data) {
  let { event = {}, starts_at = new Date(), seller_id = '' } = data;
  let { venue = {}, id = '', sale_message = '' } = event;
  let { zones = [], location = '', type = '' } = venue;

  let zoneDataObject = [];
  zoneDataObject = zones.map((item, index) => {
    return {
      id: item.id,
      name: item.name,
      prices: item.prices.map((priceItem) => {
        return { ...priceItem, allowed: 0 }
      })
    }
  });

  return {
    name: data.name,
    location: location,
    starts_at: starts_at,
    zones: zoneDataObject,
    event_id: id,
    seller_id: seller_id,
    type: type,
    sale_message: sale_message,
  }
}