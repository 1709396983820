

import axios from 'axios';
import { AccessTokenId, BASE_URL, DramatixAdmin } from './constants';
import moment from 'moment'

export function generateUrl(path) {
    return BASE_URL + path;
}

export function apiReq(endPoint, data, method, headers) {
    return new Promise((resolve, reject) => {

        headers = {
            ...headers,
        }

        const accessToken = getData(AccessTokenId);

        if (accessToken) {
            headers = {
                ...headers,
                Authorization: `Bearer ${accessToken}`
            }
        }

        if (method == 'get' || method == 'delete') {
            data = {
                params: data,
                headers
            }
        }
        axios[method](endPoint, data, { headers }).then(({ data, status: httpStatus }) => {
            if (httpStatus === 200) {
                return resolve(data);
            }
            return reject(data)
        }).catch((err) => {
            const { status = '' } = err && err.response || {}

            if (status === 401) {
                localStorage.removeItem(AccessTokenId);
                localStorage.removeItem(DramatixAdmin);
                window.location.replace('/login');
            }

            return reject(err);
        });
    })
}

export function apiPost(endPoint, data, headers = {}) {
    return apiReq(generateUrl(endPoint), data, 'post', headers);
}

export function apiDelete(endPoint, data, headers = {}) {

    return apiReq(generateUrl(endPoint), data, 'delete', headers);
}

export function apiGet(endPoint, data, headers = {}) {
    return apiReq(generateUrl(endPoint), data, 'get', headers);
}

export function apiPut(endPoint, data, headers = {}) {
    return apiReq(generateUrl(endPoint), data, 'put', headers);
}


export function getData(label) {
    const data = window && window.localStorage.getItem(label);
    return JSON.parse(data);
}

export function setLocalStorageData(label, data) {
    if (window && window.localStorage)
        localStorage.setItem(label, JSON.stringify(data));
}

export function removeLocalStorageData(label) {
    if (window && window.localStorage)
        localStorage.removeItem(label);
}


// Function Name :findZone
export function findZone(zoneObj) {
    let zoneKey = Object.keys(zoneObj).length && Object.keys(zoneObj)[0];
    return zoneKey && zoneObj[zoneKey] && zoneObj[zoneKey].selectedZone;
}

let seatRowsStart = 65;
const rowsCount = 45

// Seat Creation Function
export function createSeatArrangementStructure(seatsArrangementData = [], updatingZone = '', rowsName) {
    let data = {};

    seatRowsStart = rowsName;

    seatsArrangementData.map((item, index) => {
        let keys = Object.keys(item);
        if (keys[0]) {
            let zone = findZone(item);
            let totalSeats = item[zone] && parseInt(item[zone].totalSeats) || 0;
            let totalRows = item[zone] && parseInt(item[zone].totalRows) || 0;
            let seatArray = createSeats(totalSeats, rowsCount, zone, updatingZone, item, totalRows);
            item[zone] = { ...item[zone], seats: seatArray }
            data = { ...data, ...item }
        }
    })

    if (Object.keys(data).length)
        return { zone: { ...data } };
}

// Seat Creation Function(Fetch from Backend)
export function createSeatArrangementStructureBackend(seatsArrangementData = [], updatingZone = '', rowsName) {
    let data = {};
    seatRowsStart = rowsName

    seatsArrangementData.map((item, index) => {
        let zone = findZone(item);
        let totalSeats = item[zone] && parseInt(item[zone].totalSeats) || 0;

        //Count total number of rows on frontend not from backend
        let totalRows = item[zone] && item[zone].seats && item[zone].seats.length;

        let seatArray = item[zone]['seats'];
        item[zone] = { ...item[zone], seats: seatArray, totalRows }
        data = { ...data, ...item }
    })

    return { zone: { ...data } };
}


//Create Seats By Row And Column
export function createSeats(totalSeats, totalColumns = 15, currentZone, updatingZone, item, totalRows) {
    let seat = [];

    let current = item[currentZone];

    let totalCols = Math.ceil(totalSeats / totalColumns);

    //Return if  totalrows is 0  and totalrows is less than total totalcols
    if ((totalRows <= 0) || (totalRows < totalCols)) {
        return
    }

    //This check is used in these condition mention below:
    // 1. ) If user create the seats and then change seats number of any zone.Then we return the old value of those zone whose value is not changed.
    // if (currentZone === updatingZone) {
    let setRowIndex = 0;
    for (let rows = 0; rows < totalRows; rows++) {
        let updateRow = [];
        if (String.fromCharCode(seatRowsStart) == "[") {
            seatRowsStart = 65;
            setRowIndex++;
        }
        for (let column = 0; column < totalColumns; column++) {

            //If total seats are full. Then create the empty space in the rows.
            if (!totalSeats) {
                updateRow.push({
                    seatRowName: setRowIndex ? String.fromCharCode(seatRowsStart) + setRowIndex : String.fromCharCode(seatRowsStart),
                    isSeatExist: 0,
                    row: rows,
                    col: column,
                    seatName: ""
                })
            }
            else {
                updateRow.push({
                    seatRowName: setRowIndex ? String.fromCharCode(seatRowsStart) + setRowIndex : String.fromCharCode(seatRowsStart),
                    seatName: `${setRowIndex ? String.fromCharCode(seatRowsStart) + setRowIndex : String.fromCharCode(seatRowsStart)}-${column + 1}`,
                    row: rows,
                    col: column,
                    isSeatExist: 1
                });
                totalSeats--;
            }
        }
        seatRowsStart = seatRowsStart + 1;
        seat.push(updateRow);
        updateRow = [];
    }

    return seat;
}

{/*Circular Seat Function start */ }
export function createCircularSeatData(data, seatsPerTable = 4) {
    let abc = [];
    data.map((item, index) => {
        let updatedData = { ...item };
        let { tableData = [], zoneName = '' } = item;
        let tableArray = [];

        tableData.map((tab, tabIndex) => {
            let { totalSeats = 0, inputSeat } = tab;
            totalSeats = parseInt(totalSeats);
            let createSeatArray = [];
            [...Array(totalSeats)].map((seat, seatIndex) => {
                if (seatIndex < inputSeat)
                    createSeatArray.push({
                        seatName: `${zoneName}-T${tabIndex + 1}-S${seatIndex + 1}`,
                        selected: false,
                        isSeatExist: true,
                    });
                else
                    createSeatArray.push({
                        seatName: `${zoneName} ${seatIndex + 1}`,
                        selected: false,
                        isSeatExist: false,
                    });
            })
            tableArray.push({ ...tab, tableName: `${tabIndex + 1}`, seats: createSeatArray });

        });
        updatedData = { ...item, tableData: tableArray };
        abc.push(updatedData);
    });

    return abc;
}
{/*Circular Seat Function end */ }

// Create State array for dropdown in create event(promoter)
export function createStateDropDownData(state) {
    return state.map((item) => {
        return { value: item, name: item }
    })
}

// Create Types array for dropdown in create event(promoter)
export function createTypeDropDownData(types) {
    return types.map((item) => {
        return { value: item, name: item }
    })
}

// Create timezone array for dropdown in create event(promoter)
export function createTimezoneDropDownData(timezones) {
    let keysArray = Object.keys(timezones)
    return keysArray.map((item) => {
        return { name: timezones[item], value: item }
    })
}

// Create venues array for dropdown in create event(promoter)
export function createVenueArrayForEvent(venues) {
    return venues.map((item) => {
        return { name: item.name || "Name missing", value: item.id }
    })
}

export function activeStatus(active) {
    switch (active) {
        case 0: return 'INACTIVE'
        case 1: return 'ACTIVE'
        case 2: return 'COMPLETED'
        case 3: return 'CLOSED'
        case 4: return 'CANCELLED'
        default: return '-'
    }
}


//Return key value same name 
export function createDropdown(data) {
    return data && data.map(val => ({ 'name': val, 'value': val })) || []
}

//Timezone drowpdown
export function createTimezoneDrowpDown(data) {
    let arr = []
    if (typeof data == "undefined") return arr
    for (const [key, value] of Object.entries(data)) {
        arr.push({ 'name': value, 'value': Number(key) })
    }
    return arr
}

export function createArtistDropDown(data) {
    return data && data.map(val => ({ ...val, 'value': val.name })) || []
}
export function dateFormate(date) {
    return date ? moment(date).format('YYYY-MM-DD') : null
}

export function createVenueDropdown(data) {
    return data && data.map(val => ({ 'name': val.name, 'value': val.id })) || []
}

export const initialDate = [
    {
        id: 1,
        value: "today",
        title: "Today",
        name: "Today",
        selected: false
    },
    {
        id: 2,
        value: "week",
        title: "This Week",
        name: "This Week",
        selected: false
    },
    {
        id: 3,
        value: "month",
        title: "This Month",
        name: "This Month",
        selected: false
    },
    {
        id: 4,
        value: "last3week",
        title: "Last three Week",
        name: "Last three Week",
        selected: false
    }
]


export const chartFiltersOption = [{
    id: 1,
    value: "mostRecent",
    title: "Most Recent",
    name: "Most Recent",
    selected: false
},
{
    id: 2,
    value: "lastWeek",
    title: "Last Week",
    name: "Last Week",
    selected: false
},
{
    id: 3,
    value: "lastMonth",
    title: "Last Month",
    name: "Last Month",
    selected: false
},
{
    id: 4,
    value: "last3Month",
    title: "Last 3 Month",
    name: "Last 3 Month",
    selected: false
},
{
    id: 5,
    value: "lastYear",
    title: "Last Year",
    name: "Last Year",
    selected: false
},

{
    id: 6,
    value: "week",
    title: "This Week",
    name: "This Week",
    selected: false
},
{
    id: 7,
    value: "month",
    title: "This Month",
    name: "This Month",
    selected: false
},
{
    id: 8,
    value: "all",
    title: "All",
    name: "All",
    selected: false
}
]



export const performanceInitialDate = [{
    id: 1,
    value: "mostRecent",
    title: "Most Recent",
    name: "Most Recent",
    selected: false
},
{
    id: 2,
    value: "lastWeek",
    title: "Last Week",
    name: "Last Week",
    selected: false
},
{
    id: 3,
    value: "lastMonth",
    title: "Last Month",
    name: "Last Month",
    selected: false
},
{
    id: 4,
    value: "last3Month",
    title: "Last 3 Month",
    name: "Last 3 Month",
    selected: false
},
{
    id: 5,
    value: "lastYear",
    title: "Last Year",
    name: "Last Year",
    selected: false
},
{
    id: 6,
    value: "all",
    title: "All",
    name: "All",
    selected: false
},
]

export function getDateFormat(date) {
    return moment(date).isValid() ? moment(date).format("DD-MM-YYYY") : date
}

{/**
    @params :
    @fuction : onlineSeatSelected
 */}
export const onlineSeatSelected = (seats, data) => {
    let seatZone = seats && seats.zone || {}
    let seatsKeys = Object.keys(seatZone);
    for (let index = 0; index < seatsKeys.length; index++) {
        let key = seatsKeys[index];

        let { seats: localSeat = [] } = seatZone && seatZone[key];
        for (let ind = 0; ind < localSeat.length; ind++) {
            for (let internal = 0; internal < localSeat[ind].length; internal++) {
                let { seat_id, hold } = data;
                let seatData = localSeat[ind][internal];
                if (seatData.id === seat_id) {
                    seatZone[key]['seats'][ind][internal] = { ...seats.zone[key]['seats'][ind][internal], reservation: hold ? { id: "confirmed" } : null }
                }
            }
        }
    }

    return seats;
}

//Download file given by link 
export const downloadFile = (fileLink) => {
    if (!fileLink) return
    const dotArray = fileLink.split('.')
    const extName = dotArray[dotArray.length - 1]
    fetch(fileLink, {
        method: 'GET',
    })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `${Date.now()}.${extName}`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
}
export const downloadLinkFile = (fileLink) => {
    if (!fileLink) return
    const dotArray = fileLink.split('.')
    const extName = dotArray[dotArray.length - 1]
    const link = document.createElement('a');
    link.href = `https://api.staging.dramatix.com.au/public/api/admin/download/` + fileLink.split('admin/')[1];
    link.setAttribute(
        'download',
        `${Date.now()}.${extName}`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

//Payment status 
export function getPaymentStatus(payment_status) {
    //Payment status
    //  COMPLETE = 1;//complete
    //  CANCEL = 2;//cancel
    //  REFUND = 3;//refund
    switch (payment_status) {
        case 'complete': return "Completed";
        case 'cancel': return "Cancelled";
        case 'refund': return "Refunded";
        case 'partial_refund': return "Partial refunded";
        default: return "Pending";
    }
}


export const editEventUpdate = (event = {}) => {

    // price => dramatix_fee === override_dramatix_fee
    // percentage => booking_fee_percentage === booking_fee_additional

    const updateVenue = {
        name: event && event.venue && event.venue.name || '',
        value: event && event.venue_id || ''
    }
    let eventUpdated = {
        id: event && event.id || "",
        name: event && event.name || "",
        description: event && event.description || "",
        phone: event && event.phone || "",
        fax_lead_hours: event && event.fax_lead_hours || "",
        sale_message: event && event.sale_message || "",
        post_sale_msg: event && event.post_sale_msg || "",
        type: event && event.kind || "",
        state: event && event.state || "",
        timezone: event && event.timezone || "",
        feedback_to_seller: event && event.feedback_to_seller || 0,
        start_at: event && event.start_at || null,
        publish: event && event.publish || 0,
        venue_id: updateVenue || {},
        cc_tix_to_seller: event && event.cc_tix_to_seller || 0,
        image_url: event && event.image_url || "",
        image: event && event.image || "",
        pricing_model: event && event.pricing_model || "sell",
        zones: event && event.venue && event.venue.zones || [],
        performances: event && event.performances || [],
        vaccinated: event && event.vaccinated || false,
        dramatix_fee: event && event.dramatix_fee || 0,
        booking_fee_percentage: event && event.booking_fee_percentage || 0,
        override_dramatix_fee: event && event.override_dramatix_fee || 0,
        booking_fee_additional: event && event.booking_fee_additional || 0,
        extra_fields: event && event.extrafields || [],
    }
    return eventUpdated
}