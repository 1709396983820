export const GET_TICKETS_REQUEST = "GET_TICKETS_REQUEST";
export const GET_TICKETS_SUCCESS = "GET_TICKETS_SUCCESS";
export const GET_TICKETS_FAILED = "GET_TICKETS_FAILED";

export const GET_TICKETS_EDIT_REQUEST = "GET_TICKETS_EDIT_REQUEST";
export const GET_TICKETS_EDIT_SUCCESS = "GET_TICKETS_EDIT_SUCCESS";
export const GET_TICKETS_EDIT_FAILED = "GET_TICKETS_EDIT_FAILED";

export const GET_ZONE_SEAT_REQUEST = "GET_ZONE_SEAT_REQUEST";
export const GET_ZONE_SEAT_SUCCESS = "GET_ZONE_SEAT_SUCCESS";
export const GET_ZONE_SEAT_FAILED = "GET_ZONE_SEAT_FAILED";

export const GET_PERFORMANCE_ZONES_REQUEST = "GET_PERFORMANCE_ZONES_REQUEST";
export const GET_PERFORMANCE_ZONES_SUCCESS = "GET_PERFORMANCE_ZONES_SUCCESS";
export const GET_PERFORMANCE_ZONES_FAILED = "GET_PERFORMANCE_ZONES_FAILED";

export const GET_SEAT_DETATILS_REQUEST = "GET_SEAT_DETATILS_REQUEST";
export const GET_SEAT_DETATILS_SUCCESS = "GET_SEAT_DETATILS_SUCCESS";
export const GET_SEAT_DETATILS_FAILED = "GET_SEAT_DETATILS_FAILED";

export const OPEN_SNACKBAR_STATE = 'OPEN_SNACKBAR_STATE'
export const CLOSE_SNACKBAR_STATE = 'CLOSE_SNACKBAR_STATE'

export const GET_REFUND_REQUEST = "GET_REFUND_REQUEST";
export const GET_REFUND_SUCCESS = "GET_REFUND_SUCCESS";
export const GET_REFUND_FAILED = "GET_REFUND_FAILED";

export const GET_SEND_TO_BUYER_REQUEST = "GET_SEND_TO_BUYER_REQUEST";
export const GET_SEND_TO_BUYER_SUCCESS = "GET_SEND_TO_BUYER_SUCCESS";
export const GET_SEND_TO_BUYER_FAILED = "GET_SEND_TO_BUYER_FAILED";

export const GET_TICKET_BY_ID_REQUEST = "GET_TICKET_BY_ID_REQUEST";
export const GET_TICKET_BY_ID_SUCCESS = "GET_TICKET_BY_ID_SUCCESS";
export const GET_TICKET_BY_ID_FAILED = "GET_TICKET_BY_ID_FAILED";

export const GET_SEAT_CHANGE_REQUEST = "GET_SEAT_CHANGE_REQUEST";
export const GET_SEAT_CHANGE_SUCCESS = "GET_SEAT_CHANGE_SUCCESS";
export const GET_SEAT_CHANGE_FAILED = "GET_SEAT_CHANGE_FAILED";

export const GET_BOOKING_STATUS_CHANGE_REQUEST = "GET_BOOKING_STATUS_CHANGE_REQUEST";
export const GET_BOOKING_STATUS_CHANGE_SUCCESS = "GET_BOOKING_STATUS_CHANGE_SUCCESS";
export const GET_BOOKING_STATUS_CHANGE_FAILED = "GET_BOOKING_STATUS_CHANGE_FAILED";