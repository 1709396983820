import * as actionType from './actionsType'

const initialState = {
    isLoading: false,
    dashboard: {},
};

export default function dashboardReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case actionType.GET_DASHBOARD_REQUEST:
            return { ...state, dashboard: {}, isLoading: true };
        case actionType.GET_DASHBOARD_SUCCESS:
            return { ...state, dashboard: action.payload.data, isLoading: false };
        case actionType.GET_DASHBOARD_FAILED:
            return { ...state, dashboard: {}, isLoading: false };
        default:
            return state;
    }
}
