import * as actionType from './actionsType'
import { setLocalStorageData, removeLocalStorageData } from '../../utils'
import { AccessTokenId, DramatixAdmin } from '../../constants'

const initialState = {
    isLoading: false,
    user: [],
};

export default function signInReducer(state = { ...initialState }, action) {
    const { type, payload } = action;

    switch (type) {
        //LOGIN
        case actionType.ADMIN_LOGIN_INITIATE:
            return { ...state, user: [], isLoading: true };

        case actionType.ADMIN_LOGIN_SUCCESS:
            setLocalStorageData(AccessTokenId, payload.token)
            setLocalStorageData(DramatixAdmin, payload)
            return { ...state, user: payload, isLoading: false };

        case actionType.ADMIN_LOGIN_FAILURE:
            return { ...state, user: [], isLoading: false };

        //SIGN OUT
        case actionType.ADMIN_SIGNOUT_REQUEST:
            return { ...state, isLoading: true };

        case actionType.ADMIN_SIGNOUT_SUCCESS:
            removeLocalStorageData(AccessTokenId)
            removeLocalStorageData(DramatixAdmin)
            return { ...state, isLoading: false }
        case actionType.ADMIN_SIGNOUT_FAILED:
            return { ...state, isLoading: false };

        //CHANGE PASSWORD
        case actionType.CHANGE_PASSWORD_INITIATE:
            return { ...state, isLoading: true };

        case actionType.CHANGE_PASSWORD_SUCCESS:
            return { ...state, isLoading: false };

        case actionType.CHANGE_PASSWORD_FAILURE:
            return { ...state, isLoading: false };

        //RESET PASSWORD REQUEST
        case actionType.RESET_PASSWORD_REQUEST_INITIATE:
            return { ...state, isLoading: true };

        case actionType.RESET_PASSWORD_REQUEST_SUCCESS:
            return { ...state, isLoading: false };

        case actionType.RESET_PASSWORD_REQUEST_FAILED:
            return { ...state, isLoading: false };

        //VERIFY OTP 
        case actionType.VERIFY_OTP_INITIATE:
            return { ...state, isLoading: true };

        case actionType.VERIFY_OTP_SUCCESS:
            return { ...state, isLoading: false };

        case actionType.VERIFY_OTP_FAILED:
            return { ...state, isLoading: false };

        //RESET PASSWORD
        case actionType.RESET_PASSWORD_INITIATE:
            return { ...state, isLoading: true };

        case actionType.RESET_PASSWORD_SUCCESS:
            return { ...state, isLoading: false };

        case actionType.RESET_PASSWORD_FAILED:
            return { ...state, isLoading: false };

        //FORGOT RESEND OTP
        case actionType.FORGOT_RESEND_OTP_INITIATE:
            return { ...state, isLoading: true };

        case actionType.FORGOT_RESEND_OTP_SUCCESS:
            return { ...state, isLoading: false };

        case actionType.FORGOT_RESEND_OTP_FAILED:
            return { ...state, isLoading: false };

        default:
            return state;
    }
}
