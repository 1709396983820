import * as actionsType from './actionsType'

const initialState = {
    isLoading: false,
    eventsList: [],
    categories: [],
    eventDetails: {},
    totalEvents: 0,
    allArtist: [],
    eventFees: {}
}
export default function eventReducer(state = { ...initialState }, action) {
    const { type, payload } = action;

    switch (type) {
        case actionsType.GET_ALL_EVENTS_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_ALL_EVENTS_SUCCESS:
            let loadmore = action.loadMore || false;
            if (loadmore) {
                return {
                    ...state,
                    isLoading: false,
                    eventsList: [...state.eventsList, ...payload.all_event.data] || [],
                    totalEvents: payload.all_event.total,
                    allArtist: payload.all_artist || []
                }
            } else {
                return {
                    ...state,
                    isLoading: false,
                    eventsList: payload.all_event.data || [],
                    totalEvents: payload.all_event.total,
                    allArtist: payload.all_artist || []
                }
            }
        case actionsType.GET_ALL_EVENTS_FAILED:
            return { ...state, isLoading: false, eventsList: [] }

        case actionsType.GET_ALL_CATEGORIES_REQUEST:
            return { ...state, isLoading: true, categories: {} }
        case actionsType.GET_ALL_CATEGORIES_SUCCESS:
            return { ...state, isLoading: false, categories: payload || {} }
        case actionsType.GET_ALL_CATEGORIES_FAILED:
            return { ...state, isLoading: false, categories: {} }

        case actionsType.GET_DELETE_EVENT_BY_ID_REQUEST:
            return { ...state, isLoading: true, }
        case actionsType.GET_DELETE_EVENT_BY_ID_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_DELETE_EVENT_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsType.GET_EVENT_BY_ID_REQUEST:
            return { ...state, isLoading: true, }
        case actionsType.GET_EVENT_BY_ID_SUCCESS:
            return { ...state, isLoading: false, eventDetails: payload }
        case actionsType.GET_EVENT_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsType.GET_UPLOAD_IMAGE_REQUEST:
            return { ...state, isLoading: true, }
        case actionsType.GET_UPLOAD_IMAGE_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_UPLOAD_IMAGE_FAILED:
            return { ...state, isLoading: false }

        case actionsType.GET_UPDATE_EVENT_REQUEST:
            return { ...state, isLoading: true, }
        case actionsType.GET_UPDATE_EVENT_SUCCESS:
            return { ...state, isLoading: false }
        case actionsType.GET_UPDATE_EVENT_FAILED:
            return { ...state, isLoading: false }

        //Status Change Active/InActive
        case actionsType.STATUS_CHANGE_REQUEST: {
            return { ...state, isLoading: true }
        }

        case actionsType.STATUS_CHANGE_SUCCESS: {
            return { ...state, isLoading: false }
        }

        case actionsType.STATUS_CHANGE_FAILED: {
            return { ...state, isLoading: false }
        }
        //Status Re open 
        case actionsType.REOPEN_STATUS_CHANGE_REQUEST: {
            return { ...state, isLoading: true }
        }

        case actionsType.REOPEN_STATUS_CHANGE_SUCCESS: {
            return { ...state, isLoading: false }
        }

        case actionsType.REOPEN_STATUS_CHANGE_FAILED: {
            return { ...state, isLoading: false }
        }
        //Event fees
        case actionsType.EVENT_FEES_REQUEST: {
            return { ...state, isLoading: true }
        }
        case actionsType.EVENT_FEES_SUCCESS: {
            return { ...state, isLoading: false }
        }
        case actionsType.EVENT_FEES_FAILED: {
            return { ...state, isLoading: false }
        }

        // Get event fees
        case actionsType.GET_EVENT_FEES_REQUEST: {
            return { ...state, isLoading: true }
        }
        case actionsType.GET_EVENT_FEES_SUCCESS: {
            return { ...state, isLoading: false, eventFees: payload && payload.data || {} }
        }
        case actionsType.GET_EVENT_FEES_FAILED: {
            return { ...state, isLoading: false }
        }

        case actionsType.SEND_BUYER_EMAIL_REQUEST: {
            return { ...state, isLoading: true }
        }

        case actionsType.SEND_BUYER_EMAIL_SUCCESS: {
            return { ...state, isLoading: false }
        }

        case actionsType.SEND_BUYER_EMAIL_FAILED: {
            return { ...state, isLoading: false }
        }
        case actionsType.DELETE_EXTRA_FIELD_BY_ID_REQUEST: {
            return { ...state, isLoading: true }
        }

        case actionsType.DELETE_EXTRA_FIELD_BY_ID_SUCCESS: {
            return { ...state, isLoading: false }
        }

        case actionsType.DELETE_EXTRA_FIELDT_BY_ID_FAILED: {
            return { ...state, isLoading: false }
        }

        default:
            return state
    }

}