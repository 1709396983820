import { apiDelete, apiGet, apiPost } from "../../utils";
import { pickBy } from 'lodash'

export function getAllExpenditureAPI(data) {
    const updateData = pickBy(data)
    return apiGet('admin/expenditure/get-all', updateData);
}

export function getAddExpenditureAPI(data = {}) {
    let formData = new FormData();

    let newData = Object.keys(data)

    for (var i = 0; i < newData.length; i++) {
        formData.append(newData[i], data[newData[i]])
    }

    return apiPost('admin/expenditure', formData);

}

export function getDeleteExpenditureAPI(data) {
    return apiDelete(`admin/expenditure/${data}`);
}

export function getExpenditureByIdAPI(data) {
    return apiGet(`admin/expenditure?id=${data}`);
}

export function getUpdateExpenditureAPI(data) {
    let formData = new FormData();

    let newData = Object.keys(data)

    for (var i = 0; i < newData.length; i++) {
        formData.append(newData[i], data[newData[i]])
    }

    return apiPost('admin/expenditure/update', formData);
}

export function getProfitLossIncomeAPI(data) {
    const updateData = pickBy(data)
    return apiGet('admin/report/income', updateData);
}

export function getProfitLossExpensesAPI(data) {
    const updateData = pickBy(data)
    return apiGet('admin/report/expense', updateData);
}
