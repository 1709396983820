import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useDispatch, useSelector } from 'react-redux';
import * as commonActions from "../../containers/common/action";

export default function CustomDialog(props) {
    const dispatch = useDispatch();
    const { isOpenDialog = false } = useSelector(state => state.common)
    const { children, maxWidth, customClassName = "", isCloseIconButton } = props;

    function handleClose() {
        dispatch(commonActions.closeModal())
    }
    return (
        <div className="">
            <Dialog
                open={isOpenDialog}
                onClose={!isCloseIconButton ? handleClose : () => { }}
                aria-labelledby="form-dialog-title"
                maxWidth={maxWidth}
                className={`cus-modal ${customClassName}`}
            >
                <DialogTitle className="modal-title">
                    {
                        !isCloseIconButton ?
                            <IconButton onClick={handleClose} className="close">
                                <CloseIcon />
                            </IconButton>
                            : null
                    }
                </DialogTitle>
                <DialogContent className="dailogBox">
                    {children}
                </DialogContent>
            </Dialog>
        </div>
    );
}
