export const OPEN_SNACKBAR_STATE = 'OPEN_SNACKBAR_STATE'
export const CLOSE_SNACKBAR_STATE = 'CLOSE_SNACKBAR_STATE'

export const GET_ALL_SELLERS_REQUEST = "GET_ALL_SELLERS_REQUEST";
export const GET_ALL_SELLERS_SUCCESS = "GET_ALL_SELLERS_SUCCESS";
export const GET_ALL_SELLERS_FAILED = "GET_ALL_SELLERS_FAILED";

export const GET_SELLERS_BY_ID_REQUEST = "GET_SELLERS_BY_ID_REQUEST";
export const GET_SELLERS_BY_ID_SUCCESS = "GET_SELLERS_BY_ID_SUCCESS";
export const GET_SELLERS_BY_ID_FAILED = "GET_SELLERS_BY_ID_FAILED";

export const GET_SELLERS_EVENT_BY_ID_REQUEST = "GET_SELLERS_EVENT_BY_ID_REQUEST";
export const GET_SELLERS_EVENT_BY_ID_SUCCESS = "GET_SELLERS_EVENT_BY_ID_SUCCESS";
export const GET_SELLERS_EVENT_BY_ID_FAILED = "GET_SELLERS_EVENT_BY_ID_FAILED";

export const SELLER_ACTIVE_STATUS_REQUEST = "SELLER_ACTIVE_STATUS_REQUEST";
export const SELLER_ACTIVE_STATUS_SUCCESS = "SELLER_ACTIVE_STATUS_SUCCESS";
export const SELLER_ACTIVE_STATUS_FAILED = "SELLER_ACTIVE_STATUS_FAILED";

export const GET_ADD_SELLER_REQUEST = "GET_ADD_SELLER_REQUEST"
export const GET_ADD_SELLER_SUCCESS = "GET_ADD_SELLER_SUCCESS";
export const GET_ADD_SELLER_FAILED = "GET_ADD_SELLER_FAILED";

export const GET_UPDATE_SELLER_REQUEST = "GET_UPDATE_SELLER_REQUEST"
export const GET_UPDATE_SELLER_SUCCESS = "GET_UPDATE_SELLER_SUCCESS";
export const GET_UPDATE_SELLER_FAILED = "GET_UPDATE_SELLER_FAILED";

export const GET_PAYMENTS_BY_ID_REQUEST = "GET_PAYMENTS_BY_ID_REQUEST";
export const GET_PAYMENTS_BY_ID_SUCCESS = "GET_PAYMENTS_BY_ID_SUCCESS";
export const GET_PAYMENTS_BY_ID_FAILED = "GET_PAYMENTS_BY_ID_FAILED";

export const GET_PAYMENTS_REQUEST = "GET_PAYMENTS_REQUEST";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_FAILED = "GET_PAYMENTS_FAILED";