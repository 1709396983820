export const OPEN_SNACKBAR_STATE = 'OPEN_SNACKBAR_STATE'
export const CLOSE_SNACKBAR_STATE = 'CLOSE_SNACKBAR_STATE'

export const GET_CMS_REQUEST = "GET_CMS_REQUEST";
export const GET_CMS_SUCCESS = "GET_CMS_SUCCESS";
export const GET_CMS_FAILED = "GET_CMS_FAILED";

export const ADD_CMS_REQUEST = "ADD_CMS_REQUEST";
export const ADD_CMS_SUCCESS = "ADD_CMS_SUCCESS";
export const ADD_CMS_FAILED = "ADD_CMS_FAILED";

export const UPDATE_CMS_REQUEST = "UPDATE_CMS_REQUEST";
export const UPDATE_CMS_SUCCESS = "UPDATE_CMS_SUCCESS";
export const UPDATE_CMS_FAILED = "UPDATE_CMS_FAILED";

