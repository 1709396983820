export const OPEN_SNACKBAR_STATE = 'OPEN_SNACKBAR_STATE'
export const CLOSE_SNACKBAR_STATE = 'CLOSE_SNACKBAR_STATE'

export const GET_ALL_TRANSACTION_REQUEST = "GET_ALL_TRANSACTION_REQUEST";
export const GET_ALL_TRANSACTION_SUCCESS = "GET_ALL_TRANSACTION_SUCCESS";
export const GET_ALL_TRANSACTION_FAILED = "GET_ALL_TRANSACTION_FAILED";

export const GET_ALL_STATEMENT_REQUEST = "GET_ALL_STATEMENT_REQUEST";
export const GET_ALL_STATEMENT_SUCCESS = "GET_ALL_STATEMENT_SUCCESS";
export const GET_ALL_STATEMENT_FAILED = "GET_ALL_STATEMENT_FAILED";

