import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

export default function CustomDropzone(props) {
    const { children, customFunction = () => { }, customData = {}, formatTypeAll = false } = props;

    const onDrop = useCallback(acceptedFiles => {

        if (formatTypeAll) {
            const file = acceptedFiles[0]
            const type = file && file.type || ''

            if (type.split("/")[0] == "image") {
                const url = URL.createObjectURL(file)
                customFunction(file, url)

            } else if (type == "application/pdf") {
                const url = "/images/pdf-icon.svg"
                customFunction(file, url)

            } else {
                const url = "/images/static-doc.svg"
                customFunction(file, url)
            }

        } else {
            const file = acceptedFiles[0]
            if ((file && file.type && file.type.split("/")[0]) !== "image") return
            const url = URL.createObjectURL(file)
            customFunction(file, url)
        }
    }, [customData])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.jpeg,.jpg,.png,.pdf,.xls,.doc,.docx' })

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            {children}
        </div>
    )
}