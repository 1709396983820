import { apiGet, apiPost } from "../../utils";

export function getNotifcationAPI(data) {
    return apiGet('admin/notification', data);
}

export function getReadNotifcationAPI(data) {
    return apiPost('admin/notification-read', data);
}

export function getSeenNotifcationAPI(data) {
    return apiPost('admin/notification-seen', data);
}

export function getVenuesByStateAPI(state = '') {
    return apiGet(`admin/venue/venues-by-state?state=${state}`)
}