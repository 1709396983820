//ADMIN LOGIN
export const ADMIN_LOGIN_INITIATE = 'ADMIN_LOGIN_INITIATE';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAILURE = 'ADMIN_LOGIN_FAILURE';

// SNACKBAR
export const OPEN_SNACKBAR_STATE = 'OPEN_SNACKBAR_STATE'
export const CLOSE_SNACKBAR_STATE = 'CLOSE_SNACKBAR_STATE'

//SIGN OUT
export const ADMIN_SIGNOUT_REQUEST = 'ADMIN_SIGNOUT_REQUEST';
export const ADMIN_SIGNOUT_SUCCESS = 'ADMIN_SIGNOUT_SUCCESS';
export const ADMIN_SIGNOUT_FAILED = 'ADMIN_SIGNOUT_FAILED';

//CHANGE PASSWORD
export const CHANGE_PASSWORD_INITIATE = 'CHANGE_PASSWORD_INITIATE';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

//RESET PASSWORD REQUEST
export const RESET_PASSWORD_REQUEST_INITIATE = 'RESET_PASSWORD_REQUEST_INITIATE';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAILED = 'RESET_PASSWORD_REQUEST_FAILED';

//VERIFY OTP
export const VERIFY_OTP_INITIATE = 'VERIFY_OTP_INITIATE';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';

//RESET PASSWORD
export const RESET_PASSWORD_INITIATE = 'RESET_PASSWORD_INITIATE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

//FORGOT RESEND OTP 
export const FORGOT_RESEND_OTP_INITIATE = 'FORGOT_RESEND_OTP_INITIATE';
export const FORGOT_RESEND_OTP_SUCCESS = 'FORGOT_RESEND_OTP_SUCCESS';
export const FORGOT_RESEND_OTP_FAILED = 'FORGOT_RESEND_OTP_FAILED';