import React, { useState } from "react";
import { FormGroup, Typography, Grid, Button, Container } from "@material-ui/core";
import CustomInputField from "../common/CustomInputField"
import * as authActions from '../../containers/Auth/actions'
import * as commonActions from '../../containers/common/action'
import { useSelector, useDispatch } from 'react-redux'

import { validationChangePassword } from '../../utilities/validations/auth/changePassword'

const initialState = {
    current_password: '',
    password: '',
    confirm_password: ''
}

export default function ResetPasswordModal() {
    const [data, setData] = useState({ ...initialState })
    const [errors, setErrors] = useState({})
    const { handleSignOut = () => { } } = useSelector(state => state.common.data)
    const dispatch = useDispatch()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: ''
        })
    }

    const changePassword = () => {
        const { isValid, errors } = validationChangePassword(data);

        if (!isValid) {
            setErrors(errors)
            return
        }

        dispatch(authActions.changePassword(data)).then(res => {
            dispatch(commonActions.closeModal({}, "RESET_PASSWORD_MODAL"));
            handleSignOut()
        }).catch(err => { })
    }
    return (
        <div className="confirm-password-form">
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="changes-pass-icon">
                            <img src="/images/change-pass-icon.svg" alt="password-icon" />
                        </div>
                        <div className="cus-sec-heading text-center">
                            <Typography variant="h2">Change Password</Typography>
                        </div>
                        <FormGroup>
                            <Grid item xs={12}>
                                <CustomInputField
                                    label="Current Password"
                                    fullWidth
                                    margin="normal"
                                    name="current_password"
                                    value={data && data.current_password}
                                    error={errors && errors.current_password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInputField
                                    label="New Password"
                                    fullWidth
                                    margin="normal"
                                    name="password"
                                    value={data && data.password}
                                    error={errors && errors.password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInputField
                                    label="Confirm Password"
                                    fullWidth
                                    margin="normal"
                                    name="confirm_password"
                                    value={data && data.confirm_password}
                                    error={errors && errors.confirm_password}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button className="cus-btn blue-btn" fullWidth onClick={() => changePassword()}>CHANGE PASSWORD</Button>
                            </Grid>
                        </FormGroup>
                    </Grid>
                </Grid>
            </Container>
        </div >
    )
}