import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import reportWebVitals from './reportWebVitals';
import 'react-phone-input-2/lib/style.css'

import { Provider } from 'react-redux';
import store from './redux/store'

import Routes from './routes'

ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
    <Routes />
   </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
