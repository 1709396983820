//Modal ActionTypes
export const OPEN_MODAL_DIALOG = "OPEN_MODAL_DIALOG";
export const CLOSE_MODAL_DIALOG = "CLOSE_MODAL_DIALOG";

// SnackBar ActionTypes
export const OPEN_SNACKBAR_STATE = 'OPEN_SNACKBAR_STATE'
export const CLOSE_SNACKBAR_STATE = 'CLOSE_SNACKBAR_STATE'

export const GET_ALL_NOTIFICATION_REQUEST = "GET_ALL_NOTIFICATION_REQUEST";
export const GET_ALL_NOTIFICATION_SUCCESS = "GET_ALL_NOTIFICATION_SUCCESS";
export const GET_ALL_NOTIFICATION_FAILED = "GET_ALL_NOTIFICATION_FAILED";

export const GET_READ_NOTIFICATION_REQUEST = "GET_READ_NOTIFICATION_REQUEST";
export const GET_READ_NOTIFICATION_SUCCESS = "GET_READ_NOTIFICATION_SUCCESS";
export const GET_READ_NOTIFICATION_FAILED = "GET_READ_NOTIFICATION_FAILED";

export const GET_SEEN_NOTIFICATION_REQUEST = "GET_SEEN_NOTIFICATION_REQUEST";
export const GET_SEEN_NOTIFICATION_SUCCESS = "GET_SEEN_NOTIFICATION_SUCCESS";
export const GET_SEEN_NOTIFICATION_FAILED = "GET_SEEN_NOTIFICATION_FAILED";

export const GET_IP_ADDRESS_REQUEST = "GET_IP_ADDRESS_REQUEST";
export const GET_IP_ADDRESS_SUCCESS = "GET_IP_ADDRESS_SUCCESS";
export const GET_IP_ADDRESS_FAILED = "GET_IP_ADDRESS_FAILED";


export const GET_VENUES_BY_STATE_REQUEST = "GET_VENUES_BY_STATE_REQUEST";
export const GET_VENUES_BY_STATE_FAILED = "GET_VENUES_BY_STATE_FAILES";
export const GET_VENUES_BY_STATE_SUCCESS = "GET_VENUES_BY_STATE_SUCCESS";
