import React, { useState, useEffect } from "react";
import { FormGroup, Typography, Grid, Button, Container } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TodayIcon from '@material-ui/icons/Today';
import { useSelector } from "react-redux";
import moment from "moment";

export default function ProfitLossModal() {

    const [selectedDate, setSelectedDate] = useState({
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD')
    })

    const { data } = useSelector(state => state.common);
    const { title = '', customFunction = () => { }, buttonTitle = '', start_date, end_date } = data;

    useEffect(() => {
        setSelectedDate({
            start_date,
            end_date
        })

    }, [])

    const confirmAction = () => {
        customFunction(selectedDate);
    }

    const handleChangeDate = (event, key) => {
        let formatedDate = moment(event['_d']).format('YYYY-MM-DD');
        setSelectedDate({ ...selectedDate, [key]: formatedDate });
    }

    return (
        <div className="profit-loss-form">
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="changes-pass-icon">
                            <img src="/images/Profit-Loss-Report.svg" alt="profit-loss-img" />
                        </div>
                        <div className="cus-sec-heading text-center">
                            <Typography variant="h2">{title || 'Profit/Loss Report'}</Typography>
                        </div>
                        <FormGroup>
                            <Grid item xs={12}>
                                <div className="datepicker-date">
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            autoOk
                                            format="DD/MM/YYYY"
                                            className="date-picker-material-ui mr-5"
                                            variant="inline"
                                            inputVariant="outlined"
                                            value={selectedDate && selectedDate.start_date}
                                            InputAdornmentProps={{ position: "end" }}
                                            name="start_date"
                                            keyboardIcon={<TodayIcon />}
                                            onChange={(event) => { handleChangeDate(event, 'start_date') }}
                                            label="Start date"
                                            maxDate={moment()}
                                        />
                                        <KeyboardDatePicker
                                            autoOk
                                            format="DD/MM/YYYY"
                                            className="date-picker-material-ui ml-5"
                                            variant="inline"
                                            inputVariant="outlined"
                                            value={selectedDate && selectedDate.end_date}
                                            label="End date"
                                            InputAdornmentProps={{ position: "end" }}
                                            onChange={(event) => { handleChangeDate(event, 'end_date') }}
                                            name="end_date"
                                            keyboardIcon={<TodayIcon />}
                                            minDate={selectedDate && selectedDate.start_date}
                                            maxDate={moment()}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Button className="cus-btn blue-btn" fullWidth onClick={() => { confirmAction() }}>{buttonTitle || 'yo'}</Button>
                            </Grid>
                        </FormGroup>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}