import axios from 'axios'
import * as actionTypes from './actionTypes'
import {
    getNotifcationAPI,
    getReadNotifcationAPI,
    getSeenNotifcationAPI,
    getVenuesByStateAPI
} from './api'
export const openModal = (data = {}, modal) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.OPEN_MODAL_DIALOG,
            data: data,
            modal: modal
        })
    }
}

export function closeModal() {
    return dispatch => dispatch({ type: actionTypes.CLOSE_MODAL_DIALOG })
}

// SnackBar Actions
export const openSnackbarsState = (data) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.OPEN_SNACKBAR_STATE,
            payload: data
        })
    }
}

export const closeSnackbarsState = () => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.CLOSE_SNACKBAR_STATE
        })
    }
}

export function getNotifcation(data, loadMore = false) {
    return dispatch => {
        dispatch({
            type: actionTypes.GET_ALL_NOTIFICATION_REQUEST
        })

        return new Promise((resolve, reject) => {
            getNotifcationAPI(data).then(res => {
                dispatch({
                    type: actionTypes.GET_ALL_NOTIFICATION_SUCCESS,
                    payload: res && res.data,
                    loadMore: loadMore
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionTypes.GET_ALL_NOTIFICATION_FAILED
                })
                dispatch({
                    type: actionTypes.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

export function getReadNotifcation(data) {
    return dispatch => {
        dispatch({
            type: actionTypes.GET_READ_NOTIFICATION_REQUEST
        })

        return new Promise((resolve, reject) => {
            getReadNotifcationAPI(data).then(res => {
                dispatch({
                    type: actionTypes.GET_READ_NOTIFICATION_SUCCESS,
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionTypes.GET_READ_NOTIFICATION_FAILED
                })
                dispatch({
                    type: actionTypes.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}


// Get Seen Notifcation
export function getSeenNotifcation(data) {
   
    return dispatch => {
        dispatch({
            type: actionTypes.GET_SEEN_NOTIFICATION_REQUEST
        })

        return new Promise((resolve, reject) => {
            getSeenNotifcationAPI(data).then(res => {
             
                dispatch({
                    type: actionTypes.GET_SEEN_NOTIFICATION_SUCCESS,
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionTypes.GET_SEEN_NOTIFICATION_FAILED
                })
                dispatch({
                    type: actionTypes.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//Get api address 
export function getIpAddress() {
    return dispatch => {
        dispatch({
            type: actionTypes.GET_IP_ADDRESS_REQUEST
        })
        return new Promise((resolve, reject) => {
            axios.get('https://api.ipify.org/')
                .then(function (response) {
                    dispatch({
                        type: actionTypes.GET_IP_ADDRESS_SUCCESS,
                        payload: response && response.data,
                    })
                    resolve(response.data)
                })
                .catch(function (err) {
                    dispatch({
                        type: actionTypes.GET_IP_ADDRESS_FAILED
                    })

                    dispatch({
                        type: actionTypes.OPEN_SNACKBAR_STATE,
                        payload: {
                            open: true,
                            messageType: 'error',
                            message: err && err.response && err.response.data && err.response.data.message || err.message
                        }
                    })
                    reject(err)
                })
        })
    }
}


export function getVenuesByState(data = {}) {
    return dispatch => {
        dispatch({
            type: actionTypes.GET_VENUES_BY_STATE_REQUEST
        });
        return new Promise((resolve, reject) => {
            getVenuesByStateAPI(data).then(res => {
                dispatch({
                    type: actionTypes.GET_VENUES_BY_STATE_SUCCESS,
                    payload: res && res.data || []
                });
                return resolve(res);
            }).catch(err => {
                dispatch({
                    type: actionTypes.GET_VENUES_BY_STATE_FAILED
                });
                dispatch({
                    type: actionTypes.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err);
            })
        });
    }
}



//real time notification  vai pusher
export function getRealTimeNotifcation(notificationsData) {
   
    let bellData ={
      notifications :{
            data:notificationsData
        }   
    }

    return dispatch => {
        dispatch({
            type: actionTypes.GET_ALL_NOTIFICATION_REQUEST
        })
        dispatch({
            type: actionTypes.GET_ALL_NOTIFICATION_SUCCESS,
            payload: bellData,
        })

    }
}