import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#5EB7DF',
        },
        secondary: {
            main: '#292929'
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: 'Proxima Nova, sans-serif',
    },
    overrides: {
        MuiTextField: {
            root: {
                fontSize: '22px'
            },
        },
    }
});

export default theme;