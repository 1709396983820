export const GET_ALL_EVENTS_REQUEST = "GET_ALL_EVENTS_REQUEST";
export const GET_ALL_EVENTS_SUCCESS = "GET_ALL_EVENTS_SUCCESS";
export const GET_ALL_EVENTS_FAILED = "GET_ALL_EVENTS_FAILED";

export const GET_ALL_CATEGORIES_REQUEST = "GET_ALL_CATEGORIES_REQUEST";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_FAILED = "GET_ALL_CATEGORIES_FAILED";

export const OPEN_SNACKBAR_STATE = 'OPEN_SNACKBAR_STATE'
export const CLOSE_SNACKBAR_STATE = 'CLOSE_SNACKBAR_STATE'

export const GET_DELETE_EVENT_BY_ID_REQUEST = "GET_DELETE_EVENT_BY_ID_REQUEST";
export const GET_DELETE_EVENT_BY_ID_SUCCESS = "GET_DELETE_EVENT_BY_ID_SUCCESS";
export const GET_DELETE_EVENT_BY_ID_FAILED = "GET_DELETE_EVENT_BY_ID_FAILED";

export const GET_EVENT_BY_ID_REQUEST = "GET_EVENT_BY_ID_REQUEST";
export const GET_EVENT_BY_ID_SUCCESS = "GET_EVENT_BY_ID_SUCCESS";
export const GET_EVENT_BY_ID_FAILED = "GET_EVENT_BY_ID_FAILED";

export const GET_UPLOAD_IMAGE_REQUEST = "GET_UPLOAD_IMAGE_REQUEST";
export const GET_UPLOAD_IMAGE_SUCCESS = "GET_UPLOAD_IMAGE_SUCCESS";
export const GET_UPLOAD_IMAGE_FAILED = "GET_UPLOAD_IMAGE_FAILED";

export const GET_UPDATE_EVENT_REQUEST = "GET_UPDATE_EVENT_REQUEST";
export const GET_UPDATE_EVENT_SUCCESS = "GET_UPDATE_EVENT_SUCCESS";
export const GET_UPDATE_EVENT_FAILED = "GET_UPDATE_EVENT_FAILED";

export const STATUS_CHANGE_REQUEST = "STATUS_CHANGE_REQUEST";
export const STATUS_CHANGE_SUCCESS = "STATUS_CHANGE_SUCCESS";
export const STATUS_CHANGE_FAILED = "STATUS_CHANGE_FAILED";

export const REOPEN_STATUS_CHANGE_REQUEST = "REOPEN_STATUS_CHANGE_REQUEST";
export const REOPEN_STATUS_CHANGE_SUCCESS = "REOPEN_STATUS_CHANGE_SUCCESS";
export const REOPEN_STATUS_CHANGE_FAILED = "REOPEN_STATUS_CHANGE_FAILED";

export const GET_EVENT_FEES_REQUEST = "GET_EVENT_FEES_REQUEST";
export const GET_EVENT_FEES_SUCCESS = "GET_EVENT_FEES_SUCCESS";
export const GET_EVENT_FEES_FAILED = "GET_EVENT_FEES_FAILED";

export const EVENT_FEES_REQUEST = "EVENT_FEES_REQUEST";
export const EVENT_FEES_SUCCESS = "EVENT_FEES_SUCCESS";
export const EVENT_FEES_FAILED = "EVENT_FEES_FAILED";


export const SEND_BUYER_EMAIL_REQUEST = "SEND_BUYER_EMAIL_REQUEST";
export const SEND_BUYER_EMAIL_SUCCESS = "SEND_BUYER_EMAIL_SUCCESS";
export const SEND_BUYER_EMAIL_FAILED = "SEND_BUYER_EMAIL_FAILED";

export const DELETE_EXTRA_FIELD_BY_ID_REQUEST = "DELETE_EXTRA_FIELD_BY_ID_REQUEST";
export const DELETE_EXTRA_FIELD_BY_ID_SUCCESS = "DELETE_EXTRA_FIELD_BY_ID_SUCCESS";
export const DELETE_EXTRA_FIELDT_BY_ID_FAILED = "DELETE_EXTRA_FIELDT_BY_ID_FAILED";