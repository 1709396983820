import { isEmpty } from 'lodash'

export function validationChangePassword(data = {}) {
    let errors = {}

    if (!data.current_password) {
        errors.current_password = 'Current password is required!'
    }

    if (!data.password) {
        errors.password = 'Password is required!'
    }
    if (!data.confirm_password) {
        errors.confirm_password = 'Confirm password is required!'
    }
    if (data.current_password && data.current_password.length < 8) {
        errors.current_password = "Current password must be atleast 8 characters long!";
    }

    if (data.password && data.password.length < 8) {
        errors.password = "Password must be atleast 8 characters long!";
    }

    if (data.password && data.confirm_password && data.password != data.confirm_password) {
        errors.confirm_password = "Password does not match!"
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}