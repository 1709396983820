import * as actionsType from './actionsType'

const initialState = {
    isLoading: false,
    transactionsData: {
        transactions: [],
        total: 0,
        currentPage: 0,
        perPage: 10,
        lastPage: 0
    },
    statements: []
}
export default function transactionsReducer(state = { ...initialState }, action) {
    const { type, payload } = action;

    switch (type) {
        case actionsType.GET_ALL_TRANSACTION_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_ALL_TRANSACTION_SUCCESS:
            return { ...state, isLoading: false, transactionsData: { ...state.transactionsData, transactions: payload.data, total: payload.total, currentPage: payload.currentPage, perPage: payload.perPage, lastPage: payload.lastPage } }
        case actionsType.GET_ALL_TRANSACTION_FAILED:
            return { ...state, isLoading: false }

        case actionsType.GET_ALL_STATEMENT_REQUEST:
            return { ...state, isLoading: true }
        case actionsType.GET_ALL_STATEMENT_SUCCESS:
            return { ...state, isLoading: false, statements: payload }
        case actionsType.GET_ALL_STATEMENT_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }

}