import { isEmpty } from "lodash";
import moment from "moment";
import { numRegex } from "../../../constants";

export function createEventValidation(data, step) {
    let errors = {};
    switch (step) {
        case 1: {
            if (!data.name) {
                errors.name = "Name is required!";
            }
            if (!data.description) {
                errors.description = "Description is required!";
            }
            if (!data.type) {
                errors.type = "Type is required!";
            }
            if (!data.venue_id || !data.venue_id.value) {
                errors.venue_id = "Venue is required!";
            }
            if (!data.state) {
                errors.state = "State is required!";
            }

            if (data.phone && data.phone.length != 10) {
                errors.phone = "Phone number must be 10 digits!";
            }
            if (!data.timezone) {
                errors.timezone = "Timezone is required!";
            }
            if (!(data.fax_lead_hours).toString()) {
                errors.fax_lead_hours = "Lead Time is required!"
            }

            break;
        }
        case 2: {
            let { zones = [],
                pricing_model = '',
                dramatix_fee = 0,
                override_dramatix_fee = 0,
                booking_fee_percentage = 0,
                booking_fee_additional = 0 } = data;



            let isSelected = pricing_model === 'sell';
            let isValid = true
            let isValidData = true;
            const errors = {};

            let updatedZones = zones && zones.map(zone => {
                let { prices = [] } = zone
                let updatedPrices = [...prices]
                for (let i = 0; i < prices.length; i++) {
                    const { amount, name } = prices[i]
                    if (isSelected && i === 0 && !amount) {
                        isValid = false
                        updatedPrices[i].error = 'Price is required!'
                    } else if (isSelected && amount && (name === prices[1].name) && !prices[2].amount) {
                        isValid = false;
                        updatedPrices[2].error = "Number of people are required!"
                    } else if (isSelected && amount && (name === prices[2].name) && !prices[1].amount) {
                        isValid = false;
                        updatedPrices[1].error = 'Price is required!'
                    } else {
                        if (!isSelected) {
                            delete updatedPrices[i]['error']
                        }
                    }
                }
                return { ...zone, prices: updatedPrices }
            })

            if (!String(override_dramatix_fee)) {
                isValidData = false
                errors.override_dramatix_fee = `Dramtix fee is required!`
            }
            if (override_dramatix_fee > dramatix_fee) {
                isValidData = false
                errors.override_dramatix_fee = `Dramtix fee can't be greater than ${dramatix_fee}`
            }

            if (!String(booking_fee_additional)) {
                isValidData = false
                errors.booking_fee_additional = `Dramtix fee percentage is required!`
            }

            if (booking_fee_additional > booking_fee_percentage) {
                isValidData = false
                errors.booking_fee_additional = `Dramtix fee percentage can't be greater than ${booking_fee_percentage}`
            }

            return {
                updatedZones,
                isValid,
                isValidData,
                errors
            }
        }
        case 3: {
            let isValid = true;
            let { performances = [] } = data
            let allPerformances = [...performances];
            const totalSeats = data && data.zones && data.zones.map(zone => zone.total_seats).reduce((preVal, currentVal) => preVal + currentVal, 0)

            performances.map((performace, performaceIndex) => {

                //Clear all the errors before validation of every promotion
                let { artists = [] } = allPerformances[performaceIndex];
                let updatedArtist = [...allPerformances[performaceIndex].artists];

                {/*Validation for performance page start */ }
                //Remove after implementation on view part
                allPerformances[performaceIndex] = { ...allPerformances[performaceIndex], errors: {} };


                if (!performace.name) {
                    allPerformances[performaceIndex] = { ...allPerformances[performaceIndex], errors: { ...allPerformances[performaceIndex].errors, name: "Name is required!" } };
                    isValid = false;
                }
                if (!performace.tickets_count) {
                    allPerformances[performaceIndex] = { ...allPerformances[performaceIndex], errors: { ...allPerformances[performaceIndex].errors, tickets_count: "Please enter total number of tickets!" } }
                    isValid = false;
                }

                if (performace && performace.tickets_count && !numRegex.test(performace.tickets_count)) {
                    allPerformances[performaceIndex] = { ...allPerformances[performaceIndex], errors: { ...allPerformances[performaceIndex].errors, tickets_count: `Total number of tickets is number type.` } }
                    isValid = false;
                }

                if (performace && performace.tickets_count && Number(performace.tickets_count) < 0) {
                    allPerformances[performaceIndex] = { ...allPerformances[performaceIndex], errors: { ...allPerformances[performaceIndex].errors, tickets_count: `Total number of tickets can't be less than 0 ` } }
                    isValid = false;
                }

                if (performace && performace.tickets_count && performace.tickets_count > totalSeats) {
                    allPerformances[performaceIndex] = { ...allPerformances[performaceIndex], errors: { ...allPerformances[performaceIndex].errors, tickets_count: `Total number of tickets can't be greather than ${totalSeats}!` } }
                    isValid = false;
                }

                const isDateAfter = moment(data.start_at).isAfter(performace.starts_at)
                if (isDateAfter) {
                    allPerformances[performaceIndex] = { ...allPerformances[performaceIndex], errors: { ...allPerformances[performaceIndex].errors, date: "Date should not be before event date!" } };
                    isValid = false;
                }

                {/*Validation for artist page start */ }
                artists && artists.map((perArtist, index) => {
                    if (!perArtist.name) {
                        updatedArtist[index] = { ...perArtist, errors: { ...perArtist.errors, name: "Please enter artist name!" } }
                        isValid = false;
                    } else {
                        updatedArtist[index] = { ...perArtist };
                    }
                })
                allPerformances[performaceIndex] = { ...allPerformances[performaceIndex], artists: updatedArtist }
            })

            return {
                isValid: isValid,
                updatedPerformances: allPerformances
            }
        }
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}


export function eventFeesValidation(data) {
    let errors = {};
    const { dramatix_fee = '', booking_fee_percentage = '', paypal_booking_fee_percentage = '', paypal_dramatix_fee = '' } = data;
    if (!dramatix_fee) {
        errors.dramatix_fee = "Booking fee is required!";
    }

    if (!paypal_dramatix_fee) {
        errors.paypal_dramatix_fee = "Booking paypal fee is required!";
    }

    if (!booking_fee_percentage) {
        errors.booking_fee_percentage = "Booking fee percentage is required!";
    }

    if (!paypal_booking_fee_percentage) {
        errors.paypal_booking_fee_percentage = "Booking paypal fee percentage is required!";
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}
export function sendEmailsValidatin(data = {}) {
    let errors = {};

    if (data.email.length <= 0) {
        errors.email = "Email is required!";
    }
    if (!data.subject) {
        errors.subject = "Subject is required!";
    }

    if (!data.body) {
        errors.body = "Body is requried!";
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}