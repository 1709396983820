import {
    getAllEventAPI,
    getAllCategoriesAPI,
    getDeleteEventByIdAPI,
    getEventByIdAPI,
    uploadImagesAPI,
    getUpdateEventAPI,
    changeStatusAPI,
    eventFeesAPI,
    getEventFeesAPI,
    changeReOpenStatusAPI,
    sendBuyerEmailAPI,
    getEventExtraFieldByIdAPI
} from './api'
import * as actionsType from './actionsType'

export function getAllEvents(data, loadMore) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_ALL_EVENTS_REQUEST
        })

        return new Promise((resolve, reject) => {
            getAllEventAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_ALL_EVENTS_SUCCESS,
                    payload: res && res.data,
                    loadMore
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_ALL_EVENTS_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//Get all categories 
export function getAllCategories(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_ALL_CATEGORIES_REQUEST
        })

        return new Promise((resolve, reject) => {
            getAllCategoriesAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_ALL_CATEGORIES_SUCCESS,
                    payload: res && res.data
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_ALL_CATEGORIES_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//Delete event By Id
export function getDeleteEventById(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_DELETE_EVENT_BY_ID_REQUEST
        })

        return new Promise((resolve, reject) => {
            getDeleteEventByIdAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_DELETE_EVENT_BY_ID_SUCCESS,
                    payload: res && res.data
                })

                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'success',
                        message: res && res.message
                    }
                })

                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_DELETE_EVENT_BY_ID_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//Get event by id
export function getEventById(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_EVENT_BY_ID_REQUEST
        })

        return new Promise((resolve, reject) => {
            getEventByIdAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_EVENT_BY_ID_SUCCESS,
                    payload: res && res.data
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_EVENT_BY_ID_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//Image upload 
export function uploadImages(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_UPLOAD_IMAGE_REQUEST
        })

        return new Promise((resolve, reject) => {
            uploadImagesAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_UPLOAD_IMAGE_SUCCESS,
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_UPLOAD_IMAGE_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//Update event
export function getUpdateEvent(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_UPDATE_EVENT_REQUEST
        })

        return new Promise((resolve, reject) => {
            getUpdateEventAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_UPDATE_EVENT_SUCCESS,
                    payload: res && res.data
                })

                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'success',
                        message: res && res.message
                    }
                })

                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_UPDATE_EVENT_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}

//Change Status
export function changeStatus(data) {
    return dispatch => {
        dispatch({
            type: actionsType.STATUS_CHANGE_REQUEST
        })
        return new Promise((resolve, reject) => {
            changeStatusAPI(data).then(res => {
                dispatch({
                    type: actionsType.STATUS_CHANGE_SUCCESS,
                    payload: data
                });
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'success',
                        message: res && res.message
                    }
                })
                return resolve(res);
            }).catch(err => {
                dispatch({
                    type: actionsType.STATUS_CHANGE_FAILED
                });
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err);
            })
        })
    }
}

//Reopen change  
export function changeReOpenStatus(data) {
    return dispatch => {
        dispatch({
            type: actionsType.REOPEN_STATUS_CHANGE_REQUEST
        })
        return new Promise((resolve, reject) => {
            changeReOpenStatusAPI(data).then(res => {
                dispatch({
                    type: actionsType.REOPEN_STATUS_CHANGE_SUCCESS,
                    payload: data
                });
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'success',
                        message: res && res.message
                    }
                })
                return resolve(res);
            }).catch(err => {
                dispatch({
                    type: actionsType.REOPEN_STATUS_CHANGE_FAILED
                });
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err);
            })
        })
    }
}

//Get event fee
export function getEventFees(data) {
    return dispatch => {
        dispatch({
            type: actionsType.GET_EVENT_FEES_REQUEST
        })
        return new Promise((resolve, reject) => {
            getEventFeesAPI(data).then(res => {
                dispatch({
                    type: actionsType.GET_EVENT_FEES_SUCCESS,
                    payload: res
                });
                return resolve(res);
            }).catch(err => {
                dispatch({
                    type: actionsType.GET_EVENT_FEES_FAILED
                });
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err);
            })
        })
    }
}

// Post and Update Event fees
export function eventFees(data) {
    return dispatch => {
        dispatch({
            type: actionsType.EVENT_FEES_REQUEST
        })
        return new Promise((resolve, reject) => {
            eventFeesAPI(data).then(res => {
                dispatch({
                    type: actionsType.EVENT_FEES_SUCCESS,
                });
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'success',
                        message: res && res.message
                    }
                })
                return resolve(res);
            }).catch(err => {
                dispatch({
                    type: actionsType.EVENT_FEES_FAILED
                });
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err);
            })
        })
    }
}




export function sendBuyerEmail(data) {
    return dispatch => {
        dispatch({
            type: actionsType.SEND_BUYER_EMAIL_REQUEST
        })
        return new Promise((resolve, reject) => {
            sendBuyerEmailAPI(data).then(res => {
                dispatch({
                    type: actionsType.SEND_BUYER_EMAIL_SUCCESS,
                    payload: data
                });
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'success',
                        message: res && res.message
                    }
                })
                return resolve(res);
            }).catch(err => {
                dispatch({
                    type: actionsType.SEND_BUYER_EMAIL_FAILED
                });
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err);
            })
        })
    }
}

//Delete event  extra field 
export function getEventExtraFieldById(data) {
    return dispatch => {
        dispatch({
            type: actionsType.DELETE_EXTRA_FIELD_BY_ID_REQUEST
        })

        return new Promise((resolve, reject) => {
            getEventExtraFieldByIdAPI(data).then(res => {
                dispatch({
                    type: actionsType.DELETE_EXTRA_FIELD_BY_ID_SUCCESS,
                    payload: res && res.data
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'success',
                        message: res && res.message
                    }
                })
                return resolve(res)
            }).catch(err => {
                dispatch({
                    type: actionsType.DELETE_EXTRA_FIELDT_BY_ID_FAILED
                })
                dispatch({
                    type: actionsType.OPEN_SNACKBAR_STATE,
                    payload: {
                        open: true,
                        messageType: 'error',
                        message: err && err.response && err.response.data && err.response.data.message || err.message
                    }
                })
                return reject(err)
            })
        })

    }
}