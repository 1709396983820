import React, { useState, useEffect } from "react";
import { FormGroup, Typography, Grid, Button, Container, List, ListItem, CircularProgress, InputAdornment } from "@material-ui/core";
import CustomInputField from "../common/CustomInputField"
import CustomDropzone from "../common/CustomDropzone";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment'
import { numWithDecimalRegex, noSpaceAtBeginning } from '../../constants'
import { addExpenditureValidation } from "../../utilities/validations/promotor/reports";
import { useDispatch, useSelector } from "react-redux";
import * as reportActoions from '../../containers/Reports/actions'
import * as commonActions from '../../containers/common/action'

const initialState = {
    amount: '',
    description: '',
    paid_date: null,
    receipt: null
}
export default function ExpenditureModal() {
    const [imgUrl, setImgUrl] = useState(null)
    const [file, setFile] = useState(null)
    const [data, setData] = useState({ ...initialState })
    const [errors, setErrors] = useState({})
    const dispatch = useDispatch()
    const { isLoading, expenditureDetails = {} } = useSelector(state => state.reports)
    const { isLoading: isCommonLoading, data: comonData } = useSelector(state => state.common)
    const { edit = false, id = '' } = comonData || {}

    useEffect(() => {
        if (edit && id) {
            dispatch(reportActoions.getExpenditureById(id)).then(res => {
            }).catch(err => { })
        }
    }, [])


    useEffect(() => {
        if (edit && id) {
            setData({
                ...data,
                amount: expenditureDetails && expenditureDetails.amount,
                description: expenditureDetails && expenditureDetails.description,
                paid_date: expenditureDetails && expenditureDetails.paid_date,

            })
            if (expenditureDetails && expenditureDetails.receipt_url) {
                setImgUrl(expenditureDetails && getIcon(expenditureDetails.receipt_url))
            }
        }


    }, [expenditureDetails])

    function fileUpload(file, url) {
        setImgUrl(url)
        setFile(file)
        setData({
            ...data,
            receipt: file
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (value && !noSpaceAtBeginning.test(value)) return
        if (name == 'amount' && value && !numWithDecimalRegex.test(value)) return

        setData({
            ...data,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: ''
        })
    }

    const handleChangeDate = (event) => {
        let formatedDate = moment(event['_d']).format('YYYY-MM-DD');
        setData({ ...data, paid_date: formatedDate });
        setErrors({
            ...errors,
            paid_date: ''
        })
    }



    const handleSubmit = (e, isEdit) => {
        e.preventDefault()
        const { isValid, errors } = addExpenditureValidation(data)

        if (!isValid) {
            setErrors(errors)
            return
        }

        const updated = {
            amount: data && data.amount,
            description: data && data.description,
            paid_date: data && data.paid_date,

        }

        if (isEdit) {
            updated['id'] = id;
            if (data && data.receipt) {
                updated['receipt'] = file
            }
            dispatch(reportActoions.getUpdateExpenditure(updated)).then(res => {
                dispatch(commonActions.closeModal({}, "EXPENDITURE_MODAL"))
                dispatch(reportActoions.getAllExpenditure())
            }).catch(err => { })
        } else {

            if (data && data.receipt) {
                updated['receipt'] = file
            }

            dispatch(reportActoions.getAddExpenditure(updated)).then(res => {
                dispatch(commonActions.closeModal({}, "EXPENDITURE_MODAL"))
                dispatch(reportActoions.getAllExpenditure())
            }).catch(err => { })
        }

    }
    const getIcon = (file) => {
        if (!file) return null
        const imageUrl = file && file.split(".")[file.split(".").length - 1]
        switch (imageUrl) {
            case "jpeg":
                return file
            case "jpg":
                return file
            case "png":
                return file
            case "pdf":
                return "/images/pdf-icon.svg"
            default:
                return "/images/static-doc.svg"
        }

    }



    return (
        <div className="expenditure-form">
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <div className="changes-pass-icon">
                            <img src="/images/expenditure.svg" alt="expenditure-img" />
                        </div>
                        <div className="cus-sec-heading text-center">
                            <Typography variant="h2">Expenditure</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <List>
                                <ListItem>
                                    <div className="rep-field-form">
                                        <CustomInputField
                                            label="Amount*"
                                            fullWidth
                                            margin="normal"
                                            name="amount"
                                            value={data && data.amount || ''}
                                            onChange={handleChange}
                                            error={errors && errors.amount || ''}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                                            }}
                                        />
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <div className="rep-field-form">
                                        <CustomInputField
                                            multiline
                                            rows={3}
                                            label="Description*"
                                            fullWidth
                                            margin="normal"
                                            name="description"
                                            value={data && data.description || ''}
                                            onChange={handleChange}
                                            error={errors && errors.description || ''}

                                        />
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <div className="rep-field-form">
                                        <div className="datepicker-date">
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    autoOk
                                                    format="DD/MM/YYYY"
                                                    className="date-picker-material-ui"
                                                    variant="inline"
                                                    minDateMessage=""
                                                    // minDate={moment().add(1, 'days')}
                                                    inputVariant="outlined"
                                                    value={data && data.paid_date}
                                                    label="Date*"
                                                    name="paid_date"
                                                    onChange={(event) => { handleChangeDate(event) }}
                                                    id="paid_date"
                                                    InputAdornmentProps={{ position: "end" }}
                                                    keyboardIcon={<TodayIcon />}
                                                    fullWidth
                                                    maxDate={moment()}
                                                />

                                            </MuiPickersUtilsProvider>

                                        </div>
                                        {errors && <p className="error">{errors.paid_date || ''}</p>}
                                    </div>
                                </ListItem>
                                <ListItem>
                                    <div className="rep-field-form">
                                        <div className="upload-img-sec">
                                            <div className={imgUrl ? "upload-img-inner uploaded-img" : "upload-img-inner"} >
                                                <img className="cus-pointer" src={imgUrl ? imgUrl : "/images/upload-icon.svg "} alt="upload-icon" onClick={() => window && window.open(imgUrl, '_blank')} />
                                            </div>
                                            <CustomDropzone customFunction={fileUpload} formatTypeAll={true}>
                                                <Typography variant="h5">Upload files</Typography>
                                            </CustomDropzone>
                                        </div>
                                    </div>
                                </ListItem>
                            </List>
                            <Grid item xs={12}>
                                <Button disabled={isLoading} className="cus-btn blue-btn" fullWidth onClick={(e) => handleSubmit(e, edit)}>{isLoading ? <CircularProgress className="circular-progress" size={20} /> : 'SAVE'}</Button>

                            </Grid>
                        </FormGroup>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}