
import { isEmpty } from "lodash";
import moment from "moment";


export function addExpenditureValidation(data) {
    let errors = {};

    if (!data.amount) {
        errors.amount = 'Amount is required!'
    }
    if (!data.description) {
        errors.description = 'Description is required!'
    }

    if (!data.paid_date) {
        errors.paid_date = 'Date is required!'
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}
