import React, { useState } from "react";
import CustomInputField from "../../components/common/CustomInputField";
import { Typography, Grid, Button } from "@material-ui/core";
import { useSelector } from "react-redux";

const initialData = {
    name: ""
}
export default function ExtraFieldModal(props) {
    const [stateData, setStateData] = useState({ ...initialData });
    const [errors, setErrors] = useState({})
    const { customAction = () => { } } = useSelector(state => state.common.data)
    {/**
        name => onHandleChange
        params => event
        functionality => change the value on typing in desired field in local state
    */}
    const onHandleChange = (event) => {
        const { name = "", value = "" } = event.target;
        setStateData({ ...stateData, [name]: value });
    }

    const confirmAction = () => {
        if (!stateData.name) {
            setErrors({ name: 'Name is requried!' })
            return
        }
        customAction({ name: stateData.name });
    }

    return (
        <div className="modal-form">
            <Grid container>
                <Grid item xs={12}>
                    <div className="db-sub-heading text-center">
                        <Typography variant="h3" className="w-100">Add Field</Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="add-fileds-modal">
                        <div className="rep-field">
                            <CustomInputField
                                fullWidth
                                margin="normal"
                                label={"Name"}
                                name={"name"}
                                value={stateData.name}
                                error={errors && errors.name}
                                onChange={(event) => onHandleChange(event)}
                            />
                        </div>
                        <div className="w-100">
                            <Button
                                className="cus-btn blue-btn"
                                fullWidth
                                onClick={confirmAction}
                            >
                                SAVE
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}